import React, {Component} from 'react'

export default class HomePage extends Component {
    state = {
        pageInfo: {
            title: "Dashboard",
            icon: "fa-home"
        }
    }

    setPageInfo = () => {
        this.props.setPageInfo(this.state.pageInfo)
    }

    componentDidMount() {
        this.setPageInfo()
    }
    

    render() {
        return (
            <div className="col-xs-12">
                <h1>Home Page</h1>
            </div>
        )
    }
}