import React, {Component} from 'react'


export default class LoginForm extends Component {

    state = {
        loginInfo: {
            username: '',
            password: '',
            remember: 0
        }
    }
    // username
    onUsernameChange = (e) => {
        const username = e.target.value
        const oldInfo = this.state.loginInfo
        const newInfo = {...oldInfo, username}
        this.setState({
            loginInfo: newInfo
        })
    }

    // password
    onPasswordChange = (e) => {
        const password = e.target.value
        const oldInfo = this.state.loginInfo
        const newInfo = {...oldInfo, password}
        this.setState({
            loginInfo: newInfo
        })
    }

    // remember me
    onRememberChange = (e) => {
        const checkStatus = e.target.checked
        const remember = checkStatus ? 1 : 0
        const oldInfo = this.state.loginInfo
        const newInfo = {...oldInfo, remember}
        this.setState({
            loginInfo: newInfo
        })
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.props.onSubmit(this.state.loginInfo)
    }

    render() {
        return (
            <form onSubmit={this.onSubmit} >
                <div className="input-group mb15">
                    <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                    <input 
                        onChange={this.onUsernameChange}
                        type="text" name="username" className="form-control" placeholder="Username"/>
                </div>
                <div className="input-group mb15">
                    <span className="input-group-addon"><i className="glyphicon glyphicon-lock"></i></span>
                    <input 
                        onChange={this.onPasswordChange}
                        type="password" name="password" className="form-control" placeholder="Password"/>
                </div>
                <div className="clearfix">
                    <div className="pull-left">
                        <div className="ckbox ckbox-primary mt10">
                            <input 
                                onChange={this.onRememberChange}
                                type="checkbox" id="rememberMe" />
                            <label htmlFor="rememberMe">Remember Me</label>
                        </div>
                    </div>
                    <div className="pull-right">
                        <button type="submit" className="btn btn-success">Sign In <i className="fa fa-angle-right ml5"></i></button>
                    </div>
                </div>                      
            </form>
        )
    }

}
