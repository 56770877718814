import Store from './Store'

export default class ApiService {

    store = new Store()

    constructor() {
        this._apiBase = 'http://linkadmin.org/api'
        this._authToken = this._getAuthToken()
    }

    _getAuthToken() {
        const authInfo = this.store.getStore('userAuthInfo')
        if (authInfo) {
            const {AuthToken} = authInfo
            return AuthToken
        }
        return null
    }


    /*
        Authorization

        Log In
    */
    async userAuth(data) {
        return await this.postResources('/auth/', this._authToken, data)

    }

    async getAuthUser(token) {
        return await this.getResource('/auth/', token)
    }

    async signOut() {
        return await this.delResources('/auth/', this._authToken)
    }

    async createUser(data) {
        return await this.postResources('/users/', this._authToken, data)
    }

    /* ----------------------- */

    /* 
        
        Links
    */
    async downloadLinks(params, url) {
        const response = await this.getFile(params, url),
            blob = new Blob([response]),
            href = window.URL.createObjectURL(blob),
            a = document.createElement('a');
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = href;
        a.download = 'download.csv';
        a.click();
        window.URL.revokeObjectURL(href);
    }

    async uploadCvs(params) {
        const url = '/paid-links/'
        return await this.postResources(url, this._authToken, params)
    }

    async getFile(params, url) {
        if (url == undefined) {
            url = '/links/'
        }
        const token = this._authToken
        const getParams = this._getParams(params)
        const options = {mode: 'cors', headers: {'Auth-Token': `${token}`}}

        return await fetch(`${this._apiBase}${url}?${getParams}`, options)
            .then(response => {
                if (response.status >= 400) {
                    return Promise.reject(new Error(`${response.url}, ${response.status}`));
                }
                return response.blob().then(blob => blob);
            }).then(blob => {
                return blob;
            }).catch(err => Promise.reject(new Error(err)));
    }

    /* */

    async crowlerRecheck(data) {
        return await this.postResources(`/links/crawler/`, this._authToken, data)
    }

    async getLinks(url, params) {
        return await this.getResource(url, this._authToken, params)
    }

    async getGroupLinks(id) {
        return await this.getResource(`/links/group/${id}/`, this._authToken)
    }

    async getLinkById(id, params) {
        return await this.getResource(`/links/${id}/`, this._authToken, params)
    }

    async getLinksByName(listname, params = {}) {
        return await this.getResource(`/links/lists/${listname}/`, this._authToken, params)
    }

    async deleteLinks(params, url) {
        return await this.delResources(url, this._authToken, params)
    }

    async deleteAnchors(params) {
        return await this.delResources('/anchors/', this._authToken, params)
    }

    async addContactHistory(id, data) {
        return await this.postResources(`/links/${id}/contacts/`, this._authToken, data)
    }

    async updadeVisibility(data) {
        return await this.postResources('/links/visibility/', this._authToken, data)
    }

    async updadeStatus(data) {
        return await this.postResources('/links/status/', this._authToken, data)
    }

    async updadeCategory(category, data) {
        return await this.postResources(`/links/lists/${category}/`, this._authToken, data)
    }

    async importFile(data) {
        return await this.postResources(`/anchors/import/`, this._authToken, data)
    }

    /* 

        Anchors
    */
    async getAnchors(params) {
        return await this.getResource('/anchors/', this._authToken, params)
    }

    async getAnchorsById(id, params) {
        return await this.getResource(`/anchors/${id}/`, this._authToken, params)
    }

    async getDomains(params) {
        return await this.getResource('/links/domains/', this._authToken, params)
    }

    async checkDomains(params) {
        return await this.getResource('/links/domains/check/', this._authToken, params)
    }

    async updAnchor(id, data) {
        return await this.updResources(`/links/${id}/`, this._authToken, data)
    }

    async createAnchor(data) {
        return await this.updResources('/links/', this._authToken, data)
    }


    async calculatePoints(params) {
        return await this.getResource('/links/calculator/', this._authToken, params)
    }

    async getAnchorCloud(params) {
        return await this.getResource('/links/stats/anchor-cloud/', this._authToken, params)
    }

    /* Users */
    async getUsers(params) {
        return await this.getResource('/users/', this._authToken, params)
    }

    async getUserById(id, params) {
        return await this.getResource(`/users/${id}/`, this._authToken, params)
    }


    /* Sites */
    async getSites(params) {
        return await this.getResource('/sites/', this._authToken, params)
    }

    async getSiteById(id, params) {
        return await this.getResource(`/sites/${id}/`, this._authToken, params)
    }

    /* Stats */
    async getStats(url, params) {
        return await this.getResource(url, this._authToken, params)
    }

    /* General functions */
    async delResources(url, token, params) {
        const getParams = this._getParams(params)
        const res = await fetch(`${this._apiBase}${url}?${getParams}`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Auth-Token': `${token}`
            }
        })
        if (!res.ok) {
            throw new Error(`Could not fetch ${url}, status: ${res.status}`)
        }
        return await res.json()
    }

    async getResource(url, token, params) {
        const getParams = this._getParams(params)
        const res = await fetch(`${this._apiBase}${url}?${getParams}`, {
            mode: 'cors',
            headers: {
                'Auth-Token': `${token}`
            }
        })
        if (!res.ok) {
            throw new Error(`Could not fetch ${url}, status: ${res.status}`)
        }
        return await res.json()
    }

    async updResources(url, token, data) {
        const res = await fetch(`${this._apiBase}${url}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Auth-Token': `${token}`
            },
            body: JSON.stringify(data)
        })
        if (!res.ok) {
            throw new Error(`Could not fetch ${url}, status: ${res.status}`)
        }
        return await res.json()
    }

    async postResources(url, token, data, params) {
        const getParams = this._getParams(params)
        const res = await fetch(`${this._apiBase}${url}?${getParams}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Auth-Token': `${token}`
            },
            body: JSON.stringify(data)
        })
        if (!res.ok) {
            throw new Error(`Could not fetch ${url}, status: ${res.status}`)
        }
        return await res.json()
    }

    async requestCheckIndexingPage(params) {
        return await this.getResource(`/indexing-site/`, this._authToken, params)
    }

    async createIndexingPage(data) {
        return await this.postResources('/indexing-site/', this._authToken, data)
    }



    async getRefDomains(params) {
        return await this.getResource(`/ahrefs/refdomains/`, this._authToken, params)
    }

    async getTraffic(params) {
        return await this.getResource(`/ahrefs/traffic/`, this._authToken, params)
    }


    _objectToFormData(obj) {
        const formData = new FormData()
        for (let field in obj) {
            formData.append(field, obj[field] !== null ? obj[field] : '')
        }
        return formData;
    }


    _getParams(param = {}) {
        // ForceCache=1
        let str = ''
        for (let key in param) {
            str += `&${key}=${param[key]}`
        }
        return str
    }
}