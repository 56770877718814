import React, {Component} from 'react'
import {Switch, Route, Redirect} from "react-router-dom"

import Store from '../services/Store'

// components
import PageSidebar from './PageSidebar'
import PageHeader from './PageHeader'

// pages 
import HomePage from '../Pages/HomePage'
import AllLinksPage from '../Pages/AllLinksPage'
import MyLinksPage from '../Pages/MyLinksPage'
import ClaculatorPage from '../Pages/ClaculatorPage'
import DraftsLinksPage from '../Pages/DraftsLinksPage'
import ProblematicLinksPage from '../Pages/ProblematicLinksPage'
//import JsLinksPage from '../../Pages/JsLinksPage/'
import DeletedPage from '../Pages/DeletedPage'
import FailedLinksPage from '../Pages/FailedLinksPage'
import AnchorsPage from '../Pages/AnchorsPage'
import KpiPage from '../Pages/KpiPage'
import NotFoundPage from '../Pages/NotFoundPage'
import PaidLinksPage from "../Pages/PaidLinksPage";
import StatsPage from "../Pages/StatsPage";

export default class PageLayout extends Component {
    store = new Store()

    state = {
        defaultPage: "/all-links",
        pageInfo: {
            title: "",
            icon: ""
        },
        sidebar: null
    }

    setPageInfo = (pageInfo) => this.setState({pageInfo})

    onToggleSidebar = sidebar => {
        this.setState({sidebar})
    }

    checkPage = () => {
        const currentUrl = window.location.pathname + window.location.search + window.location.hash
        const beforeUrl = this.store.getStore('currentUrl')

        // console.log(`${currentUrl} and ${beforeUrl}`)

        if (!beforeUrl || currentUrl !== beforeUrl) {
            this.store.setStore(currentUrl, 'currentUrl')
        }

        // console.log(`upd: ${currentUrl} and ${beforeUrl}`)

    }

    componentDidUpdate() {
        this.checkPage()
    }

    componentWillReceiveProps() {
        this.onToggleSidebar(this.props.onToggleSidebar)
    }

    componentDidMount() {
        this.onToggleSidebar(this.props.onToggleSidebar)
        const userRole = this.store.getStore('userRole');
        this.setState({userRole})
    }

    render() {
        const {defaultPage, pageInfo, sidebar, userRole} = this.state
        const {AccountInfo} = this.props
        let pageClassList = sidebar ? 'mainwrapper' : 'mainwrapper collapsed'
        return (
            <section>
                <div className={pageClassList}>
                    <>
                         {/*<Redirect to={defaultPage} />*/}
                        {Object.keys(AccountInfo).length !== 0 ?
                            <PageSidebar AccountInfo={AccountInfo} setDefaultPage={defaultPage}/> : null
                        }
                        <div className="mainpanel">
                            <PageHeader setPageInfo={pageInfo}/>
                            <div className="contentpanel">
                                <div className="row">
                                    <Switch>
                                        <Route exact path="/">
                                            <Redirect to="/all-links"/>
                                            <HomePage setPageInfo={this.setPageInfo}/>
                                        </Route>
                                        <Route path="/all-links">
                                            <AllLinksPage setPageInfo={this.setPageInfo}/>
                                        </Route>
                                        {userRole === 'admin' || userRole === 'manager' || userRole === 'seoshnik' ? (
                                            <Route path="/paid-links">
                                                <PaidLinksPage setPageInfo={this.setPageInfo}/>
                                            </Route>
                                        ) : ''}
                                        <Route path="/my-links">
                                            <MyLinksPage setPageInfo={this.setPageInfo}/>
                                        </Route>
                                        <Route exact path="/calculator">
                                            <ClaculatorPage  setPageInfo={this.setPageInfo}/>
                                        </Route>

                                        <Route path="/calculator/:id" render={
                                            ({match}) => {
                                                const {id} = match.params
                                                return <ClaculatorPage  activeLink={id} setPageInfo={this.setPageInfo}/>
                                            }
                                        }/>
                                        <Route path="/drafts">
                                            <DraftsLinksPage setPageInfo={this.setPageInfo}/>
                                        </Route>
                                        <Route path="/problematic-links">
                                            <ProblematicLinksPage setPageInfo={this.setPageInfo}/>
                                        </Route>
                                        <Route path="/deleted-links">
                                            <DeletedPage setPageInfo={this.setPageInfo}/>
                                        </Route>
                                        <Route path="/failed">
                                            <FailedLinksPage setPageInfo={this.setPageInfo}/>
                                        </Route>
                                        <Route path="/anchor">
                                            <AnchorsPage setPageInfo={this.setPageInfo}/>
                                        </Route>
                                        <Route path="/kpi">
                                            {userRole !== 'seoshnik' ? (
                                                <KpiPage setPageInfo={this.setPageInfo}/>
                                            ) : (
                                                <Redirect to="/all-links"/>
                                            )}

                                        </Route>
                                        {userRole !== 'linkbuilder' ? (
                                            <Route path="/stats">
                                                <StatsPage setPageInfo={this.setPageInfo}/>
                                            </Route>
                                        ) : null}
                                        <Redirect to="/all-links"/>

                                        {/*<Route component={<Redirect to="/all-links"/>}></Route>*/}
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </section>
        )
    }
}