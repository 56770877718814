import React, { Component } from 'react';

import ApiService from '../services/ApiService'


export default class UploadFile extends Component {
    api = new ApiService()

    state = {
        uploadForm: false,
        file: '',
        File: ''
    }

    onUploadForm = () => {
        this.setState({uploadForm: true})
    }

    file2base64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = () => resolve(window.btoa(reader.result));
            reader.onerror = error => reject(error);
        });
    }
    
    _handleFileChange = (e) => {
        e.preventDefault()
        let file = e.target.files[0];
        this.file2base64(file) 
            .then(File => {
                this.setState({file,File}) 
            })
    }
    onImportFile = (e) => {
        e.preventDefault()
        const {File} = this.state 
        const data = {File}
        this.api.importFile(data)
            .then(res => {
                this.props.handleLoadedFile()
            })
            .catch(res => console.log(res))
    }

    render() {
        const {uploadForm, file} = this.state
        if (!uploadForm) {
            return (
                <button type="button" onClick={() => this.onUploadForm()} className="btn btn-sm btn-danger">Import</button>
            )
        }

        let inputFileName = 'browse... (only *.csv)'
        if (file) {
            inputFileName = file.name
        }

        return (

            <form onSubmit={(e) => this.onImportFile(e)} className="input-group">
                <label className="btn btn-sm m0 mr10 btn-default">
                    {inputFileName} <input type="file"  onChange={(e)=>this._handleFileChange(e)} hidden />
                </label>
                <button type="submit" className="btn btn-sm btn-danger">Send</button>
            </form>
        )
    }
}