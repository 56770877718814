import React, {Component} from 'react'

import {
    BrowserRouter as Router,
    Redirect,
    Link
  } from "react-router-dom"


import Store from '../services/Store'
import ApiService from '../services/ApiService'
import 'react-notifications/lib/notifications.css';

//pages 
import LoginPage from '../Pages/LoginPage/'
import PageLayout from '../components/PageLayout'

// components
import Loader from '../components/loader/'
import Header from '../components/Header/layout'
import NotificationContainer from "react-notifications/lib/NotificationContainer";

export default class App extends Component  {

    store = new Store()
    api = new ApiService()

    state = {
        loadingApp: true,
        isLogged: false,
        AuthAccountInfo: {},
        sidebar: true,

        activePage: 'all-links'
    }

    onAppLoaded = () => {
        this.setState({
            loadingApp: false
        })
    }

    // Authorization
    _setAuthUser = (user) => {
        const {UserId, Email, Name, Surname, Role, Points, Sites, SitesArray} = user
        const AuthAccountInfo = {UserId, Email, Name, Surname, Role, Points, Sites, SitesArray}

        const beforeUrl = this.store.getStore('currentUrl')
        const {activePage} = this.state

        let redirectPage = activePage
        
        if (beforeUrl) {
            redirectPage = beforeUrl
        }

        this.setState({
            AuthAccountInfo, 
            loadingApp: false, 
            isLogged: true,
            activePage: redirectPage
        })


        
    }
    _getAuthUser = (token) => {
        this.api.getAuthUser(token)
            .then(res => {
                if (res) {
                    return this._setAuthUser(res)
                }
                this.onAppLoaded()
            })
    }
    checkAuthUser = () => {
        const userAuthStore = this.store.getStore('userAuthInfo')
        if (!userAuthStore)
            return this.onAppLoaded()               
        
        const {AuthToken} = userAuthStore
        this._getAuthUser(AuthToken)
    }


    createUser = () => {
        const data = {
            Email: "test2@test.test",
            Password: "test2@test.test",
            Name: "Ryan",
            Surname: "Gosling",
            Role: "admin"
        }

        this.api.createUser(data)
            .then(res => console.log(res))
            .catch(res => console.log(res))
    }

    // before render
    componentDidMount() {
        console.log(this.props)
        this.checkAuthUser()
    }


    onLogin = () => {
        this.checkAuthUser()
    } 


    onSignOut = () => {
        // console.log('sign out')
        this.api.signOut()
            .then(res => console.log(res))
        this.store.removeStore('userAuthInfo')
        window.location.href = '/'
    }

    toggleSidebar = () => {
        const {sidebar} = this.state
        this.setState({sidebar: !sidebar})
    }
    
    render() {
        const {loadingApp, isLogged} = this.state
        if (loadingApp) {
            return <div className="fullscreen-Loader"><Loader/></div>
        }
        if (!isLogged) {
            return (                 
                <Router>
                    <Redirect to='/auth' />
                    <LoginPage onLogin={this.onLogin}/>
                </Router> 
            )
        }

        const {AuthAccountInfo, sidebar, activePage} = this.state
        return (
            <Router>
                {/*<Redirect to={activePage} />*/}
                <Header toggleSidebar={this.toggleSidebar} onSignOut={this.onSignOut} />
                <PageLayout onToggleSidebar={sidebar} AccountInfo={AuthAccountInfo} />
                 <NotificationContainer></NotificationContainer>
            </Router>
        )

    }
}
