import React from 'react';

const CheckedShow = ({issetChecked, showList, resetChecked}) => {
    if (!issetChecked) {
        return null
    }
    const resetBtn = showList === "checked" ? <button onClick={(e) => resetChecked(e, 'reset')} type="button" className="btn btn-sm btn-danger mr10">Reset Checked</button> : null
    const showBtnLabel = showList === "checked" ? 'Show All' : 'Show Checked'
    const showBtnEvent = showList === "checked" ? (e) => resetChecked(e, 'all') : (e) => resetChecked(e, 'checked')
    return (
        <div className="col-md-4">
            <button type="button" onClick={showBtnEvent} className="btn btn-sm btn-info mr10">{showBtnLabel}</button>
            {resetBtn}
        </div>
    )
}
export default CheckedShow
