import React, {Component} from 'react'

import DatePicker from 'react-datepicker'
import CheckedShow from './CheckedShow'

import Store from '../services/Store'

import 'react-datepicker/dist/react-datepicker.css'
import UploadLinksFile from "./UploadLinksFile";

export default class LinksActions extends Component {
    store = new Store()

    state = {
        dataPickers: {
            DateFrom: 0,
            DateTo: 0,
            minDateTo: 0,
            maxDateFrom: 0
        },
        anchorCloudAll: false
    }

    openAnchorCloud = () => {
        const {anchorCloudAll} = this.state
        this.setState({anchorCloudAll: !anchorCloudAll})
    }

    dateSelect = (date, input) => {
        const timestamp = date.getTime()
        const {dataPickers} = this.state
        const toDay = new Date().getTime()
        if (input === 'from') {
            const {DateTo} = dataPickers
            if (DateTo === 0) {
                const newDate = {...dataPickers, DateFrom: timestamp, minDateTo: timestamp, DateTo: toDay}
                return this.setState({dataPickers: newDate})
            }
            const newDate = {...dataPickers, DateFrom: timestamp, minDateTo: timestamp}
            return this.setState({dataPickers: newDate})
        }
        if (input === 'to') {
            const newDate = {...dataPickers, DateTo: timestamp, maxDateFrom: timestamp}
            return this.setState({dataPickers: newDate})
        }
    }

    onResetDate = () => {
        const date = {DateFrom: 0, DateTo: 0}
        this.props.selectDate(date)
    }

    onShowByDate = () => {
        const {DateFrom, DateTo} = this.state.dataPickers
        if (DateFrom !== 0 || DateTo !== 0) {
            const date = {DateFrom, DateTo}
            this.props.selectDate(date)
        }
        return false
    }

    downloadCsv = () => this.props.downloadCsv()
    onCrowlerRecheck = () => this.props.onCrowlerRecheck()

    handlerDelete = () => this.props.handlerDelete()

    onCheckedShowAction = (e, info) => {
        e.preventDefault()
        this.props.handlerCheckedShow(info)
    }

    initUsersList = users => {
        const {usersList} = this.state
        if (usersList !== users)
            this.setState({usersList: users})
    }

    initSitesList = sites => {
        const {sitesList} = this.state
        if (sitesList !== sites)
            this.setState({sitesList: sites})
    }

    componentDidUpdate() {
        const {users, sites} = this.props.actionParamsList
        if (users)
            this.initUsersList(users)

        if (sites)
            this.initSitesList(sites)
    }

    componentDidMount() {
        const userRole = this.store.getStore('userRole')
        const userSites = this.store.getStore('sitesArray')
        this.setState({userRole})
        this.setState({userSites})
    }

    render() {
        const {
            exportChecked,
            onCrowlerRecheck,
            onMoveTo,
            onMarkAs,
            onChangeMoveTo,
            downloadCsv,
            submitCvs,
            visibleList,
            listChecked,
            handlerCheckUser,
            handlerSelectAnchorType,
            handlerSelectLinkType,
            handlerChangeSite,
            isShow,
            anchorCloud,
            setActiveAnchor,
            activeAnchor
        } = this.props
        const {DateFrom, DateTo, minDateTo, maxDateFrom} = this.state.dataPickers
        const {userRole, userSites, anchorCloudAll} = this.state

        let exportBtn = 'btn btn-sm mr10 btn-dark'
        let exportBtnLabel = 'Export All'
        let deleteBtn
        if (exportChecked === 'checked') {
            exportBtnLabel = 'Export Checked'
            exportBtn += ' btn-danger'
        }

        if ((userRole === 'admin' || userRole === 'manager') && this.props.isChecked) {
            deleteBtn = <button type="button" className="btn btn-sm btn-danger"
                                onClick={() => this.handlerDelete()}>Delete</button>
        }

        const {usersList, sitesList} = this.state

        let usersListTMPL
        if (usersList) {
            usersListTMPL = usersList.map(user => {
                const {UserId, Name} = user
                return <option key={UserId} value={UserId}>{Name}</option>
            })
        }

        let sitesListTMPL
        if (sitesList) {
            sitesListTMPL = sitesList.map(site => {
                const {Domain, Id} = site
                return (
                    <div key={Id} className="checkbox d-inline mr10">
                        <label><input
                            onChange={(e) => handlerChangeSite(e)}
                            type="checkbox" name="SitesIds" value={Id}/>{Domain}</label>
                    </div>
                )
            })
        }

        let currentUserSitesTMPL
        if (userSites) {
            currentUserSitesTMPL = userSites.map(site => {
                const {Domain, Id} = site
                return (
                    <div key={Id} className="checkbox d-inline mr10">
                        <label><input
                            onChange={(e) => handlerChangeSite(e)}
                            type="checkbox" name="SitesIds" value={Id}/>{Domain}</label>
                    </div>
                )
            })
        }
        return (

            <div className="row mb30">
                {isShow.anchorCloud && anchorCloud ?
                    <div className="anchor__wrap">
                        {activeAnchor && activeAnchor !== '' ?
                            <div className="filter"><span>Active now: </span> {activeAnchor} <i
                                onClick={() => setActiveAnchor('')} className='fa fa-close'></i></div>
                            : ""
                        }

                        <div className="anchor-cloud">
                            {!anchorCloudAll ?
                                Object.entries(anchorCloud.anchor).slice(0, 25).map((item, value) => {
                                    return <div onClick={() => setActiveAnchor(item[0])}
                                                key={value}>{item[0]} | {anchorCloud.anchorCount[item[0]]} | {item[1].toFixed(2)}% </div>
                                })
                                :
                                Object.entries(anchorCloud.anchor).map((item, value) => {
                                    return <div onClick={() => setActiveAnchor(item[0])}
                                                key={value}>{item[0]} | {anchorCloud.anchorCount[item[0]]} | {item[1].toFixed(2)}%</div>
                                })}
                        </div>
                        {Object.keys(anchorCloud.anchor).length > 25 ?
                            <div className="anchor-cloud-button"
                                 onClick={() => this.openAnchorCloud()}>{anchorCloudAll ? "Close" : "Open all"}</div>
                            : ""
                        }
                    </div>
                    : ""}
                <br/>
                <div className="col-xs-12 mb20">
                    {isShow.crowler ?
                        <CheckedShow
                            resetChecked={this.onCheckedShowAction}
                            issetChecked={listChecked}
                            showList={visibleList}/>
                        : ""
                    }

                    <div className="col-md-4 text-right pull-right"
                         style={isShow.upload ? {display: "flex", alignItems: "center"} : {}}>
                        {isShow.crowler ?
                            <button type="button" onClick={() => onCrowlerRecheck()}
                                    className="btn btn-sm btn-info mr10">Crawler Recheck
                            </button>
                            : ""
                        }

                        <button className={exportBtn} onClick={() => downloadCsv()}>{exportBtnLabel}</button>
                        {isShow.upload ?
                            <UploadLinksFile/>
                            : ""
                        }
                        {deleteBtn}
                    </div>
                </div>

                <div className="col-xs-12 col-lg-6 mb30">
                    <div className="col-md-4">
                        <DatePicker dateFormat="d MMMM, yyyy" selected={DateFrom} maxDate={maxDateFrom}
                                    onChange={(date) => this.dateSelect(date, 'from')} customInput={<CustomPicker/>}/>
                    </div>
                    <div className="col-md-4">
                        <DatePicker dateFormat="d MMMM, yyyy" selected={DateTo} minDate={minDateTo}
                                    onChange={(date) => this.dateSelect(date, 'to')} customInput={<CustomPicker/>}/>
                    </div>
                    <div className="col-md-4">
                        <button
                            onClick={this.onShowByDate}
                            type="button" className="btn btn-sm btn-info mr10">Show
                        </button>
                        <button
                            onClick={this.onResetDate}
                            type="button" className="btn btn-sm btn-info">Reset
                        </button>
                    </div>
                </div>

                {window.location.pathname !== '/deleted-links' && window.location.pathname !== '/failed' && userRole !== 'seoshnik' ? (
                    <div className="col-xs-12 col-lg-6 test-class">
                        {isShow.LinkType ?
                            <>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="col-xs-8">
                                        <select onChange={(e) => onChangeMoveTo(e)} className="form-control">
                                            <option value="problematic">Problematiс Links</option>
                                            <option value="deleted">Deleted Links</option>
                                            {/*<option value="published">Published</option>*/}
                                            {/*<option value="failed">Failed link</option>*/}
                                            {/*<option value="draft">Drafts</option>*/}
                                        </select>
                                    </div>
                                    <div className="col-xs-4">
                                        <button type="button" onClick={() => onMoveTo()}
                                                className="btn btn-sm btn-default">Move
                                            to
                                        </button>
                                    </div>
                                </div>
                                {listChecked ? (
                                    <div className="col-xs-12 col-sm-6 ">
                                        <button type="button" onClick={() => onMarkAs()}
                                                className="btn btn-sm btn-default">Mark
                                            as not Visible
                                        </button>
                                    </div>
                                ) : null}
                            </> : ""}
                    </div>
                ) : null}

                <div className="col-xs-12 mb30">
                    <div className="row">
                        {isShow.linkType ?
                            <div className="col-xs-12 col-sm-6">
                                <div className="col-xs-12 col-sm-6">
                                    sort by LinkType:
                                    <select className="form-control" onChange={(e) => handlerSelectLinkType(e)}>
                                        <option value="">All</option>
                                        <option value="GB">GB</option>
                                        <option value="PIY">PIY</option>
                                        <option value="Profile">Profile</option>
                                        <option value="Forum">Forum</option>
                                        <option value="Duplicate">Duplicate</option>
                                        <option value="Paid">Paid</option>
                                        <option value="PR-Post">PR-Post</option>
                                    </select>
                                </div>
                            </div>
                            : ""}
                        {isShow.anchor ?
                            <div className="col-xs-12 col-sm-6">
                                <div className="col-xs-12 col-sm-6">
                                    sort by AnchorType:
                                    <select className="form-control" onChange={(e) => handlerSelectAnchorType(e)}>
                                        <option value="">All</option>
                                        <option value="brand">brand</option>
                                        <option value="url">url</option>
                                        <option value="generic">generic</option>
                                        <option value="keyword">keyword</option>
                                    </select>
                                </div>
                            </div>
                            : ""}
                    </div>
                </div>

                {userRole !== 'linkbuilder' ? (
                    <div className="col-xs-12">
                        <div className="row">
                            <div className="col-xs-12 col-md-3">
                                {isShow.users ?
                                    <select onChange={(e) => handlerCheckUser(e)} name="activeUserId"
                                            className="form-control">
                                        <option value="">All Users</option>
                                        {usersList ? usersListTMPL : null}
                                    </select>
                                    : ""}
                            </div>
                            <div className="col-xs-12 col-md-9">
                                {sitesListTMPL}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="col-xs-12">
                        <div className="row">
                            <div className="col-xs-12">
                                {currentUserSitesTMPL}
                            </div>
                        </div>
                    </div>
                )}
            </div>

        )
    }
}

const CustomPicker = ({value, onClick}) => {
    return (
        <div className="input-group">
            <input type="text" defaultValue={value} className="form-control hasDatepicker" onClick={onClick}/>
            <span className="input-group-addon" onClick={onClick}><i
                className="glyphicon glyphicon-calendar"></i></span>
        </div>
    )
}