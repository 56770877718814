import React, {Component} from 'react'

import Store from '../../services/Store'
import ApiService from '../../services/ApiService'

import LinksList from '../../components/LinksList'

export default class DraftsLinksPage extends Component {
    
    api = new ApiService()
    store = new Store()
    state = {
        pageInfo: {
            title: "Drafts",
            icon: "fa-archive"
        },
        params: {
            Status: 'draft'
        }
    }


    setPageInfo = () => {
        this.props.setPageInfo(this.state.pageInfo)

        const userRole = this.store.getStore('userRole')
        if (userRole === 'linkbuilder' || userRole === 'seoshnik') {
            const {UserId} = this.store.getStore('userAuthInfo')
            const {params} = this.state
            const newParams = {...params, UserId}
            this.setState({params: newParams})
        }     
        
    }

    componentWillMount() {
        this.setPageInfo()
    }

    render() {
        const {params} = this.state
        return (
            <div className="col-xs-12">
                <div className="table-responsive">
                    <LinksList pageParams={params} />              
                </div>
            </div>
        )
    }
}