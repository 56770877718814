import React, {Component} from 'react'

import ApiService from '../../services/ApiService'
import LinksList from '../../components/LinksList'

export default class AllLinksPage extends Component {
    api = new ApiService()

    state = {
        pageInfo: {
            title: "All links",
            icon: "fa-list-alt"
        },
        params: {
            // Limit: 50
        }
    }


    setPageInfo = () => {
        this.props.setPageInfo(this.state.pageInfo)
    }
    componentWillMount() {
        this.setPageInfo()
    }


    render() {
        const {params} = this.state
        return (
            <div className="col-xs-12">
                <div className="table-responsive">

                    <LinksList pageParams={params} />              
                    
                </div>
            </div>
        )
    }
}