import React, {Component} from 'react'
import {Redirect, withRouter} from 'react-router-dom'

import AsyncSelect from 'react-select/async'
import ApiService from '../../services/ApiService'
import {NotificationManager} from "react-notifications";
import DatePicker from "react-datepicker";
import Store from "../../services/Store";

class ClaculatorPage extends Component {
    api = new ApiService()
    store = new Store()


    state = {
        userRole: '',
        pageInfo: {
            title: "Calculator",
            icon: "fa-bar-chart-o"
        },
        LinkId: '',
        formData: {
            Domain: "",
            TrustFlow: 0,
            CitationFlow: 0,
            ReferringDomains: 0,
            Traffic: 0,
            Geo: "",
            LinkType: "",
            Rel: "",
            ExternalLinks: 0,
            Content: "",
            Price: 0,
            Link: "",
            Anchor: "",
            Url: "",
            AnchorType: "",
            Notes: "",
            AnchorId: null,
            DateUnix: 0,
            AdditionalFields: [],
            UserId: ""
        },

        initData: {
            LinkId: '',
            formData: {
                Anchor: '',
                AnchorId: null,
                Domain: "",
                TrustFlow: 0,
                CitationFlow: 0,
                ReferringDomains: 0,
                Traffic: 0,
                Geo: "",
                LinkType: "",
                Rel: "",
                ExternalLinks: 0,
                Content: "",
                Price: 0,
                Link: "",
                Url: "",
                DateUnix: 0,
                AnchorType: "",
                Notes: "",
                UserId: ""
            }
        },

        formHasEmptyField: true,
        Points: null,
        addBtnClick: false,
        redirect: false,
        additionalFields: '',

        domainUsage: {
            isActive: false,
            IsUsed: null,
            Sites: []
        },

        actionParams: {
            sites: null
        }
    }

    resetRata = () => {
        const {LinkId, formData} = this.state.initData
        return this.setState({LinkId, formData})
    }

    dateSelect = (date) => {
        const timestamp = date.getTime()
        this.setState({formData: {...this.state.formData, DateUnix: timestamp}})
    }

    setPageInfo = () => {
        this.props.setPageInfo(this.state.pageInfo)
    }

    _getValueByName = name => {
        const {formData} = this.state
        const input = formData.filter(item => item.name === name)
        return input[0].value
    }

    _setFormData = (name, value) => {
        const {formData} = this.state
        const newData = {...formData}
        if (name === "ContentStolen") {
            newData["Content"] = value
        }
        newData[name] = value
        this.setState({
            formData: newData
        }, this.formHasEmptyFieldHandler())
    }

    _getAnchorInfoById = id => {
        this.api.getAnchorsById(id)
            .then(res => {
                const {Anchor, Url, AnchorType, AnchorId} = res
                const resData = {Anchor, Url, AnchorType, AnchorId}
                const {formData} = this.state

                const newFormData = {...formData, ...resData}

                this.setState({
                    formData: newFormData
                }, this.formHasEmptyFieldHandler())
            })
    }

    _getAnchorInfoByLinkId = id => {
        this.api.getLinkById(id)
            .then(res => {
                const {
                    Id,
                    AnchorId,
                    Domain,
                    TrustFlow,
                    CitationFlow,
                    ReferringDomains,
                    Traffic,
                    Geo,
                    LinkType,
                    Rel,
                    ExternalLinks,
                    Content,
                    Price,
                    Link,
                    Anchor,
                    Url,
                    AnchorType,
                    Notes,
                    DateUnix,
                    AdditionalFields,
                    User
                } = res
                const resData = {
                    Domain,
                    TrustFlow,
                    CitationFlow,
                    ReferringDomains,
                    Traffic,
                    Geo,
                    LinkType,
                    Rel,
                    ExternalLinks,
                    Content,
                    Price,
                    Link,
                    Anchor,
                    Url,
                    AnchorType,
                    Notes,
                    DateUnix,
                    AdditionalFields,
                    User
                }

                const {formData} = this.state
                const newFormData = {
                    ...resData,
                    // --------
                    // эта шняга проверяет, если есть у ключа значение 
                    // - оно его оставляет, если нет - подставляет с базы
                    //
                    ...(Object.fromEntries(Object.entries(formData).filter(el => el[1] ? el && el === 0 : false))),
                    AnchorType,
                    LinkType,
                    Rel,
                    AnchorId,
                }

                // console.log(newFormData.Date)

                newFormData.DateUnix = new Date(newFormData.DateUnix * 1000).getTime();
                // console.log(newFormData.Date)

                this.setState({
                    AnchorId,
                    LinkId: Id,
                    formData: newFormData
                }, this.formHasEmptyFieldHandler())
            })
    }

    formHasEmptyFieldHandler = () => {
        setTimeout(() => {
            const {formData} = this.state
            let formResult = false
            for (let i in formData) {
                if (!formData[i] && formData[i] !== 0 && i !== "Notes" &&i !== "User" && i !== "UserId" && i !== "Price" && i !== "Content") {
                    formResult = true
                    break
                }
            }
            this.setState({
                formHasEmptyField: formResult
            })
        }, 200)
    }

    calculateEstimatedPoints = () => {
        const {TrustFlow, CitationFlow, ReferringDomains, LinkType, Rel} = this.state.formData
        const obj = {TrustFlow, CitationFlow, ReferringDomains, LinkType, Rel}
        if (LinkType !== "Duplicate") {
            this.api.calculatePoints(obj)
                .then(res => {
                    const {Points} = res
                    this.setState({Points})
                })
        } else {
            this.setState({Points: 12})
        }

    }

    matchDomains = value => {
        // Domain autocomplite
        if (value || value === "" || value === 0) {
            try {
                const newValue = value.match('^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/\n]+)')
                if (newValue[1]) {
                    const newData = {...this.state.formData, Domain: newValue[1]}
                    this.setState({
                        formData: newData
                    })
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    // Автокоплит домена из линки и проверка его занятости
    checkUsageDomains = value => {
        this.matchDomains(value);
        const {Domain, SiteId, LinkType} = this.state.formData

        if (Domain) {
            const baseSites = JSON.parse(localStorage.getItem('sites'));
            let baseSitesId = Object.keys(baseSites);
            const params = {Domain, SitesIds: SiteId || baseSitesId, LinkType};
            this.api.checkDomains(params).then(res => {
                this.onDomainCheckHandler(res)
                // console.log(res.Domain)
                // Ну типа Апиха к ahrefs
                // this.api.getRefDomains({Url: 'ahrefs.com'}).then(res => {
                //     if (res.data != undefined) {
                //         this.setState({
                //             formData: {ReferringDomains: res.data.stats.refdomains}
                //         })
                //     }
                // });
                // this.api.getTraffic({Url: 'ahrefs.com'}).then(res => {
                //     if (res.data != undefined) {
                //         this.setState({
                //             formData: {Traffic: res.data.metrics.traffic}
                //         })
                //     }
                // });
            })
        }
    }

    // обработчикЗанятостиДомена
    onDomainCheckHandler = ({IsUsed, Sites}) => {
        const userRole = this.store.getStore('userRole');
        const newData = {
            isActive: true,
            IsUsed,
            Sites
        }
        if (IsUsed == null && Sites == null) {
            newData.isActive = false;
        }

        if (userRole === 'admin' || userRole === 'manager') {
            newData.isActive = false;
            newData.IsUsed = null;
            newData.Sites = [];

        }

        this.setState({
            domainUsage: newData
        })
    }

    onDomainCheckHandlerReset = () => {
        const newData = {
            isActive: false,
            IsUsed: null,
            Sites: []
        }
        this.setState({
            domainUsage: newData
        })
    }

    // inputChangeHandler
    inputChange = (e, type) => {
        let value = e.target.value
        const name = e.target.name
        const {formData} = this.state


        if (type === "int") {
            value = value.replace(/\D/g, '')
            value = value ? parseInt(value, 10) : 0
        }

        if (name === 'Domain') {
            try {
                const newValue = value.match('^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/\n]+)')
                if (newValue[1]) {
                    value = newValue[1]
                }
            } catch (e) {
                console.log(e)
            }
        }
        this._setFormData(name, value)

        if (name === 'TrustFlow' || name === 'CitationFlow' || name === 'ReferringDomains' || name === 'LinkType' || value !== 'Duplicate' || name === 'Rel') {
            setTimeout(() => {
                this.calculateEstimatedPoints()
            }, 200)
        }

        // autocomplite
        if (name === 'Link') {
            setTimeout(() => {
                this.checkUsageDomains(value)
            }, 200)
        }

        if (name == 'LinkType') {
            this.setState({formData: {...formData, AdditionalFields: [], LinkType: value}})
        }
        if (name === 'LinkType' && value === 'Duplicate' && value === 'PR-Post') {
            this.onDomainCheckHandlerReset()
        } else if (name === 'LinkType') {
            setTimeout(() => {
                this.checkUsageDomains(formData.Domain)
            }, 200)
        }


        // // check usage Domain
        if (name === 'Domain') {
            // если поле пустое
            if (!value) {
                //сбросить проверку
                this.onDomainCheckHandlerReset()
            } else {
                // или проверить занятость домена
                if ((name === 'Domain' || formData.Domain !== '') && formData.LinkType === 'Duplicate') {
                    this.onDomainCheckHandlerReset()
                } else {
                    setTimeout(() => {
                        this.checkUsageDomains(value)
                    }, 200)
                }
            }

        }

    }

    addNewInput = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                AdditionalFields: [...this.state.formData.AdditionalFields, {Anchor: '', Url: ''}]
            }
        })
    }

    // когда выбран анкор из списка
    onSelectAnchor = data => {
        this.onDomainCheckHandlerReset()

        const {value, label, AnchorId, AnchorType, Url, SiteId} = data
        const newData = {...this.state.formData, Anchor: label, AnchorId, AnchorType, Url, SiteId}
        this.setState({
            formData: newData
        })

        setTimeout(() => {
            this.checkUsageDomains()
        }, 200)
    }

    // когда выбран анкор из списка
    onSelectAnchorAddition = (data, id) => {
        const {value, label, AnchorId, AnchorType, Url, SiteId} = data
        let newData = [...this.state.formData.AdditionalFields]
        newData[id] = {...newData[id], Anchor: label, AnchorId, AnchorType, Url, SiteId, LinkId: value || "",}
        this.setState({
            formData: {
                ...this.state.formData,
                AdditionalFields: newData
            }
        })

    }

    onSelectUser = data => {
        console.log(data);
        const {value, label} = data
        const newData = {...this.state.formData, UserId: value}
        this.setState({
            formData: newData
        })
    }

    // при вводе поиска анкора
    getOptionsAnchors = value => {
        const Search = value
        const params = {Search, State: 'unused'}
        return this.api.getAnchors(params)
            .then(res => {
                // список всех свободных анкоров по поисковому запросу
                let options = []
                res.anchors.forEach(anchor => {
                    // Берем название, ид ссылки и анкора
                    options.push({
                        value: anchor.LinkId || '',
                        label: anchor.Anchor + ((anchor.SiteId == 1) ? ' - WOW' : ((anchor.SiteId == 2) ? ' - PH' : '')),
                        AnchorId: anchor.AnchorId || '',
                        AnchorType: anchor.AnchorType || '',
                        Url: anchor.Url || '',
                        SiteId: +anchor.SiteId || null
                    })
                })
                return options
            })
    }

    getOptionsUsers = value => {
        const Search = value
        const params = {Search}

        return this.api.getUsers(params)
            .then(res => {
                let options = []
                res.forEach(user => {
                    options.push({
                        value: user.Id || '',
                        label: user.FullUsername
                    })
                })
                return options
            })
    }

    onSaveLinks = (e, action) => {
        e.preventDefault()
        if (action !== 'published') {
            NotificationManager.error(`Filed status`)
            return null;
        }
        const {LinkId, formData, formHasEmptyField} = this.state
        const {Content, ContentStolen} = this.state.formData
        let stolen = {}
        if (Content !== "Outsource" && Content !== "In-house" && Content !== "None" && Content !== "") {
            stolen = {Content: ContentStolen}
        }
        const status = {Status: action}
        const data = {...formData, ...stolen, ...status}

        if (data.DateUnix > 0) {
            data.DateUnix = data.DateUnix / 1000
        }

        if (formHasEmptyField && action === 'published') {
            console.log(formHasEmptyField)
            this.setState({addBtnClick: true})
            return false
        }
        console.log(LinkId)

        if (LinkId) {
            this.api.updAnchor(LinkId, data)
                .then((res) => {
                    if (res.Errors !== undefined) {
                        NotificationManager.error(`Errors: ${res.Message}`)
                    } else {
                        NotificationManager.success(`Links was update as ${action}`)
                        // alert()
                        if (this.props.fromModal == undefined) {
                            this.setState({redirect: true})
                        }
                    }
                })
        } else {
            this.api.createAnchor(data)
                .then((res) => {
                    if (res.Errors !== undefined) {
                        NotificationManager.error(`Errors: ${res.Message}`)
                    } else {
                        NotificationManager.success(`Links was update as ${action}`)
                        // alert(`Links was update as ${action}`)
                        if (this.props.fromModal == undefined) {
                            this.setState({redirect: true})
                        }
                    }
                })
        }
    }

    componentDidMount() {
        const userRole = this.store.getStore('userRole');
        this.setState({userRole})
        if (this.props.location.search !== '') {
            let searchParams = new URLSearchParams(this.props.location.search)
            let url = searchParams.get("url")
            let link = searchParams.get("link")
            let anchor = searchParams.get("anchor")
            let user = searchParams.get("user")
            let linkType = searchParams.get("linktype")
            let rel = searchParams.get("rel")
            let tf = searchParams.get("tf")
            let cf = searchParams.get("cf")
            let rd = searchParams.get("rd")
            let traffic = searchParams.get("traffic")

            if (anchor != null) {
                setTimeout(() => {
                    this.getOptionsAnchors(anchor)
                        .then((data) => {
                            if (data.length !== 0) {
                                this.onSelectAnchor(data[0])
                            }
                        })
                }, 1)
            }

            if (user != null) {
                setTimeout(() => {
                    this.getOptionsUsers(user)
                        .then((data) => {
                            if (data.length !== 0) {
                                this.onSelectUser(data[0])
                            }
                        })
                }, 1)
            }

            if (link != null) {
                setTimeout(() => {
                    this.inputChange({target: {value: link, name: 'Link'}})
                }, 1)
            }

            if (url != null) {
                setTimeout(() => {
                    this.inputChange({target: {value: url, name: 'Url'}})
                }, 1)
            }

            if (linkType != null) {
                setTimeout(() => {
                    this.inputChange({target: {value: linkType, name: 'LinkType'}})
                }, 1)
            }

            if (rel != null) {
                setTimeout(() => {
                    this.inputChange({target: {value: rel, name: 'Rel'}})
                }, 1)
            }

            if (tf != null) {
                setTimeout(() => {
                    this.inputChange({target: {value: tf, name: 'TrustFlow'}}, 'int')
                }, 1)
            }

            if (cf != null) {
                setTimeout(() => {
                    this.inputChange({target: {value: cf, name: 'CitationFlow'}}, 'int')
                }, 1)
            }

            if (rd != null) {
                setTimeout(() => {
                    this.inputChange({target: {value: rd, name: 'ReferringDomains'}}, 'int')
                }, 1)
            }

            if (traffic != null) {
                setTimeout(() => {
                    this.inputChange({target: {value: traffic, name: 'Traffic'}}, 'int')
                }, 1)
            }
        }

        if (this.props.setPageInfoEnable == undefined) {
            this.setPageInfo();
        }

        if (this.props.activeLink) {
            this._getAnchorInfoByLinkId(this.props.activeLink)
        }
    }

    render() {
        // notes
        const {
            Anchor,
            Domain,
            LinkType,
            TrustFlow,
            CitationFlow,
            ReferringDomains,
            Traffic,
            Geo,
            ExternalLinks,
            Content,
            ContentStolen,
            Price,
            Link,
            Url,
            AnchorType,
            Rel,
            Notes,
            DateUnix,
            AdditionalFields,
            User
        } = this.state.formData

        let ContentSelect = Content
        if (Content !== "Outsource" && Content !== "In-house" && Content !== "None" && Content !== "") {
            ContentSelect = 'Stolen'
        }

        const classContentSelect = ContentSelect === "Stolen" ? 'col-sm-2' : 'col-sm-6'
        const classContentStolen = ContentSelect === "Stolen" ? 'col-sm-4' : 'hidden'

        const {addBtnClick, Points, redirect, userRole} = this.state
        const estimPoints = Points ? `Estimated Points: ${Points}` : null

        const {isActive, IsUsed, Sites} = this.state.domainUsage
        return (

            <div className="col-xs-12">
                {redirect ? <Redirect to="/my-links"/> : null}

                <div className="panel panel-default">
                    <div className="panel-body nopadding">

                        <form className="form-horizontal form-bordered">
                            <div className={addBtnClick && !Anchor ? "has-error form-group" : "form-group"}>
                                <label className="col-offset-3 col-sm-3 control-label">Anchor</label>
                                <div className="col-sm-6">
                                    <AsyncSelect
                                        placeholder={Anchor ? Anchor : "Type here to search unused Anchor"}
                                        onChange={value => this.onSelectAnchor(value)}
                                        loadOptions={this.getOptionsAnchors}/>
                                </div>
                            </div>

                            <div className={addBtnClick && !Url ? "has-error form-group" : "form-group"}>
                                <label className="col-offset-3 col-sm-3 control-label">URL</label>
                                <div style={{'position': 'relative'}} className="col-sm-6">
                                    <input onChange={(e) => this.inputChange(e)} value={Url} name="Url" type="text"
                                           placeholder="URL" className="form-control"/>
                                    {LinkType && LinkType === "PR-Post" ?
                                        <div className="add-new-input" onClick={() => this.addNewInput()}
                                             style={{
                                                 'position': 'absolute',
                                                 'right': 0,
                                                 'bottom': '-28px',
                                                 'cursor': 'pointer'
                                             }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="green" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                                            </svg>
                                        </div> : ''}
                                </div>

                            </div>

                            {
                                AdditionalFields.length !== 0 ?
                                    AdditionalFields.map((item, index) => {
                                        return <React.Fragment key={index}>
                                            <div
                                                className={addBtnClick && !item.Anchor ? "has-error form-group" : "form-group"}>
                                                <label
                                                    className="col-offset-3 col-sm-3 control-label">Anchor {index + 2}</label>
                                                <div className="col-sm-6">
                                                    <AsyncSelect
                                                        placeholder={item.Anchor ? item.Anchor : "Type here to search unused Anchor"}
                                                        onChange={value => this.onSelectAnchorAddition(value, index)}
                                                        loadOptions={this.getOptionsAnchors}/>
                                                </div>
                                            </div>

                                            <div
                                                className={addBtnClick && !item.Url ? "has-error form-group" : "form-group"}>
                                                <label
                                                    className="col-offset-3 col-sm-3 control-label">URL {index + 2}</label>
                                                <div style={{'position': 'relative'}} className="col-sm-6">
                                                    <input onChange={(e) => this.inputChange(e)} value={item.Url}
                                                           name="Url" type="text"
                                                           placeholder="URL" className="form-control"/>
                                                </div>

                                            </div>
                                        </React.Fragment>
                                    })
                                    : null
                            }


                            <div className={addBtnClick && !Link ? "has-error form-group" : "form-group"}>
                                <label className="col-offset-3 col-sm-3 control-label">Link</label>
                                <div className="col-sm-6">
                                    <input onChange={(e) => this.inputChange(e)} value={Link} type="text" name="Link"
                                           placeholder="Links" className="form-control"/>
                                </div>
                            </div>

                            <div className={addBtnClick && !Domain ? "has-error form-group" : "form-group"}>
                                <label className="col-offset-3 col-sm-3 control-label">Domain</label>
                                <div className="col-sm-6">
                                    <input onChange={(e) => this.inputChange(e)} value={Domain} type="text"
                                           name="Domain" placeholder="Domain" className="form-control"/>

                                    {isActive ? (
                                        IsUsed ? (
                                            <div>
                                                <p className="text-danger">Domain is used</p>
                                                {Sites ? (
                                                    <p className="text-info">
                                                        {Sites.map(item => (
                                                            <span style={{display: "block"}}>- {item}</span>
                                                        ))}
                                                    </p>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <p className="text-success">Domain is free</p>
                                        )
                                    ) : null}
                                </div>
                            </div>

                            <div
                                className={addBtnClick && !TrustFlow && TrustFlow !== 0 ? "has-error form-group" : "form-group"}>
                                <label className="col-offset-3 col-sm-3 control-label">Trust Flow</label>
                                <div className="col-sm-6">
                                    <input onChange={(e) => this.inputChange(e, "int")} value={TrustFlow} type="text"
                                           name="TrustFlow" placeholder="Trust Flow" className="form-control"/>
                                </div>
                            </div>

                            <div
                                className={addBtnClick && !CitationFlow && CitationFlow !== 0 ? "has-error form-group" : "form-group"}>
                                <label className="col-offset-3 col-sm-3 control-label">Citation Flow</label>
                                <div className="col-sm-6">
                                    <input onChange={(e) => this.inputChange(e, "int")} value={CitationFlow} type="text"
                                           name="CitationFlow" placeholder="Citation Flow" className="form-control"/>
                                </div>
                            </div>

                            <div
                                className={addBtnClick && !ReferringDomains && ReferringDomains !== 0 ? "has-error form-group" : "form-group"}>
                                <label className="col-offset-3 col-sm-3 control-label">Referring Domains</label>
                                <div className="col-sm-6">
                                    <input onChange={(e) => this.inputChange(e, "int")} value={ReferringDomains}
                                           type="text" name="ReferringDomains" placeholder="Referring Domains"
                                           className="form-control"/>
                                </div>
                            </div>

                            <div
                                className={addBtnClick && !Traffic && Traffic !== 0 ? "has-error form-group" : "form-group"}>
                                <label className="col-offset-3 col-sm-3 control-label">Traffic</label>
                                <div className="col-sm-6">
                                    <input onChange={(e) => this.inputChange(e, "int")} value={Traffic} type="text"
                                           name="Traffic" placeholder="Traffic" className="form-control"/>
                                </div>
                            </div>

                            <div className={addBtnClick && !Geo ? "has-error form-group" : "form-group"}>
                                <label className="col-offset-3 col-sm-3 control-label">GEO</label>
                                <div className="col-sm-6">
                                    <input onChange={(e) => this.inputChange(e)} value={Geo} type="text" name="Geo"
                                           placeholder="Geo" className="form-control"/>
                                </div>
                            </div>

                            <div className={addBtnClick && !LinkType ? "has-error form-group" : "form-group"}>
                                <label className="col-offset-3 col-sm-3 control-label">Link Type</label>
                                <div className="col-sm-6">
                                    <select onChange={(e) => this.inputChange(e)} name="LinkType"
                                            className="form-control">
                                        <option value="" hidden="">Select link type</option>
                                        <option selected={LinkType && LinkType === "GB" ? true : false} value="GB">GB
                                        </option>
                                        <option selected={LinkType && LinkType === "PIY" ? true : false}
                                                value="PIY">PIY
                                        </option>
                                        <option selected={LinkType && LinkType === "Forum" ? true : false}
                                                value="Forum">Forum
                                        </option>
                                        <option selected={LinkType && LinkType === "Profile" ? true : false}
                                                value="Profile">Profile
                                        </option>
                                        <option selected={LinkType && LinkType === "Image" ? true : false}
                                                value="Image">Image
                                        </option>
                                        <option selected={LinkType && LinkType === "Duplicate" ? true : false}
                                                value="Duplicate">Duplicate
                                        </option>
                                        <option selected={LinkType && LinkType === "Paid" ? true : false}
                                                value="Paid">Paid
                                        </option>
                                        <option selected={LinkType && LinkType === "PR-Post" ? true : false}
                                                value="PR-Post">PR-Post
                                        </option>

                                    </select>
                                </div>
                            </div>

                            <div className={addBtnClick && !Rel ? "has-error form-group" : "form-group"}>
                                <label className="col-offset-3 col-sm-3 control-label">REL</label>
                                <div className="col-sm-6">
                                    <select onChange={(e) => this.inputChange(e)} name="Rel" className="form-control">
                                        <option value="" hidden="">Select rel</option>
                                        <option selected={Rel && Rel === "dofollow" ? true : false}
                                                value="dofollow">Dofollow
                                        </option>
                                        <option selected={Rel && Rel === "nofollow" ? true : false}
                                                value="nofollow">Nofollow
                                        </option>
                                        <option selected={Rel && Rel === "js" ? true : false} value="js">JS</option>
                                    </select>
                                </div>
                            </div>
                            {/* Start comment */}
                            {/*<div*/}
                            {/*    className={addBtnClick && !ExternalLinks && ExternalLinks !== 0 ? "has-error form-group" : "form-group"}>*/}
                            {/*    <label className="col-offset-3 col-sm-3 control-label">External Links</label>*/}
                            {/*    <div className="col-sm-6">*/}
                            {/*        <input onChange={(e) => this.inputChange(e, "int")} value={ExternalLinks}*/}
                            {/*               name="ExternalLinks" type="text" placeholder="External Links"*/}
                            {/*               className="form-control"/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className={addBtnClick && !ContentSelect ? "has-error form-group" : "form-group"}>*/}
                            {/*    <label className=" col-sm-3 control-label">Content</label>*/}
                            {/*    <div className={classContentSelect}>*/}
                            {/*        <select onChange={(e) => this.inputChange(e)} value={ContentSelect} name="Content"*/}
                            {/*                className="form-control">*/}
                            {/*            <option value="" hidden="">Select content</option>*/}
                            {/*            <option value="Outsource">Outsource</option>*/}
                            {/*            <option value="In-house">In-house</option>*/}
                            {/*            <option value="Stolen">Stolen</option>*/}
                            {/*            <option value="None">None</option>*/}
                            {/*        </select>*/}
                            {/*    </div>*/}

                            {/*    <div className={classContentStolen}>*/}
                            {/*        <input onChange={(e) => this.inputChange(e)} value={ContentStolen} type="text"*/}
                            {/*               name="ContentStolen" placeholder="Stolen link" className="form-control"/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="form-group">*/}
                            {/*    <label className="col-offset-3 col-sm-3 control-label">Price</label>*/}
                            {/*    <div className="col-sm-6">*/}
                            {/*        <input onChange={(e) => this.inputChange(e, "int")}*/}
                            {/*               value={+Price === 0 ? "Free" : Price} type="text" name="Price"*/}
                            {/*               placeholder="Price" className="form-control"/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/* End comment */}
                            <div className={addBtnClick && !AnchorType ? "has-error form-group" : "form-group"}>
                                <label className="col-offset-3 col-sm-3 control-label">Anchor Type</label>
                                <div className="col-sm-6">
                                    <select onChange={(e) => this.inputChange(e)} name="AnchorType"
                                            className="form-control">
                                        <option value="" hidden="">Select anchor type</option>
                                        <option selected={AnchorType && AnchorType === "brand" ? true : false}
                                                value="Brand">Brand
                                        </option>
                                        <option selected={AnchorType && AnchorType === "url" ? true : false}
                                                value="URL">URL
                                        </option>
                                        <option selected={AnchorType && AnchorType === "generic" ? true : false}
                                                value="Generic">Generic
                                        </option>
                                        <option selected={AnchorType && AnchorType === "keyword" ? true : false}
                                                value="Keyword">Keyword
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-offset-3 col-sm-3 control-label">Notes</label>
                                <div className="col-sm-6">
                                    <input onChange={(e) => this.inputChange(e)} value={Notes} type="text" name="Notes"
                                           placeholder="Notes" className="form-control"/>
                                </div>
                            </div>
                            {this.props.fromModal !== undefined && (userRole === 'admin' || userRole === 'manager') ?
                                <div className="form-group">
                                    <label className="col-offset-3 col-sm-3 control-label">DateAdded</label>
                                    <div className="col-sm-6">

                                        <DatePicker dateFormat="yyyy-MM-dd HH:mm:ss" selected={DateUnix}
                                                    showTimeSelect={true}
                                                    onChange={(date) => this.dateSelect(date)}/>


                                    </div>
                                </div> : null}

                            {(userRole === 'admin' || userRole === 'manager') ?
                                <div className="form-group">
                                    <label className="col-offset-3 col-sm-3 control-label">User</label>
                                    <div className="col-sm-6">
                                        <AsyncSelect
                                            placeholder={User ? User : "Select User"}
                                            onChange={value => this.onSelectUser(value)}
                                            loadOptions={this.getOptionsUsers}/>
                                    </div>
                                </div> : null}

                            {estimPoints ? (
                                <div className="form-group list-group-item-danger">
                                    <div className="col-xs-12">
                                        <h5 className="text-center">{estimPoints}</h5>
                                    </div>
                                </div>
                            ) : null}

                            <div className="form-group">
                                <div className="col-sm-offset-3 col-sm-6">
                                    {/*<button onClick={(e) => this.onSaveLinks(e, 'draft')}*/}
                                    {/*        className="btn btn-default pull-left">Save Drafts*/}
                                    {/*</button>*/}
                                    {/*<button onClick={(e) => this.onSaveLinks(e, 'published')}*/}
                                    {/*        className="btn btn-success pull-right">Add Link*/}
                                    {/*</button>*/}
                                    {this.props.fromModal !== undefined ?
                                        <button onClick={(e) => {
                                            this.onSaveLinks(e, 'published');
                                            this.props.onClose()
                                        }}
                                                className="btn btn-success pull-right">Save
                                        </button> :
                                        <button onClick={(e) => this.onSaveLinks(e, 'published')}
                                                className="btn btn-success pull-right">Add Link
                                        </button>
                                    }
                                    {/*<button onClick={(e) => this.onSaveLinks(e, 'failed')}*/}
                                    {/*        className="btn btn-info pull-right mr-2">Move to Failed*/}
                                    {/*</button>*/}
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ClaculatorPage)