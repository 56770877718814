import React, {Component} from 'react'
import {Link, withRouter} from "react-router-dom"

import Store from '../services/Store'


class PageSidebar extends Component {
    store = new Store()

    state = {
        menuList: [
            // {id: 0, label: "Dashboard", path: "/", icon: "fa-home", isActive: false},
            {id: 1, label: "All Links", path: "/all-links", icon: "fa-list-alt", isActive: false},
            {id: 2, label: "My Links", path: "/my-links", icon: "fa-link", isActive: false},
            {id: 3, label: "Paid Links", path: "/paid-links", icon: "fa-external-link", isActive: false},
            {id: 4, label: "Calculator", path: "/calculator", icon: "fa-bar-chart-o", isActive: false},
            // {id: 5, label: "Drafts", path: "/drafts", icon: "fa-archive", isActive: false},
            {id: 6, label: "Anchors", path: "/anchor", icon: "fa-anchor", isActive: false},
            // {id: 6, label: "Used domains", path: "/used-domains", icon: "fa-unlock", isActive: false},
            // {id: 7, label: "Problematic Links", path: "/problematic-links", icon: "fa-exclamation-triangle", isActive: false},
            //{id: 8, label: "Links in JS", path: "/links-in-js", icon: "fa-code", isActive: false},
            {id: 9, label: "Deleted links", path: "/deleted-links", icon: "fa-trash-o", isActive: false},
            // {id: 10, label: "Failed", path: "/failed", icon: "fa-chain-broken", isActive: false},
            {id: 11, label: "KPIs", path: "/kpi", icon: "fa-users", isActive: false},

            {id: 22, label: "Stats", path: "/stats", icon: "fa-area-chart", isActive: false},
            // {id: 12, label: "Promo", path: "/promo", icon: "fa-bullhorn", isActive: false},
            // {id: 13, label: "Knowledge Base", path: "/knowledge-base", icon: "fa-sitemap", isActive: false},
            // {id: 14, label: "CNN (News)", path: "/news", icon: "fa-rss", isActive: false}
        ],

        AccountInfo: {
            Name: '',
            Surname: '',
            Role: '',
            Points: ''
        },

        userId: null
    }

    _setDefaultMenuList = (url) => {
        let {menuList} = this.state

        if (!url) {
            return null
        }

        let paths = menuList.filter((item) => {
            if (item.path === url) {
                return item
            }
        })

        if (paths.length === 0) {
            url = '/all-links'
        }

        this.setState(({menuList}) => {
            let urlClear = url.split('/')
            urlClear = '/' + urlClear[1]
            // console.log(urlClear)
            const index = menuList.findIndex(elem => elem.path === urlClear)

            let old = {...menuList, isActive: false}
            old = menuList[index]
            const newItem = {...old, isActive: true}
            const newArr = [...menuList.slice(0, index), newItem, ...menuList.slice(index + 1)]
            return {
                menuList: newArr
            }
        })
    }

    _resetDefaultMenuList = () => {
        this.setState(({menuList}) => {
            let resetList = menuList.map(item => {
                return {...item, isActive: false}
            })
            return {
                menuList: resetList
            }
        })
    }
    _setActiveMenuList = (id) => {
        this._resetDefaultMenuList()
        this.setState(({menuList}) => {
            const index = menuList.findIndex(elem => elem.id === id)
            let old = {...menuList, isActive: false}
            old = menuList[index]
            const newItem = {...old, isActive: true}
            const newArr = [...menuList.slice(0, index), newItem, ...menuList.slice(index + 1)]
            return {
                menuList: newArr
            }
        })
        console.log(this.state)
    }

    onSelectPage = (id) => {
        this._setActiveMenuList(id)
    }


    setAccountInfo = () => {
        const {Name, Surname, Role, Points, Sites, SitesArray, Email} = this.props.AccountInfo
        this.store.setStore(Role, 'userRole')
        this.store.setStore({ Name, Surname, Role, Email}, 'AccountInfo')
        this.store.setStore(Sites, 'sites')
        this.store.setStore(SitesArray, 'sitesArray')
        return (
            this.setState({
                AccountInfo: {
                    Name, Surname, Role, Points
                }
            })
        )
    }

    componentDidMount() {
        let url = this.props.location.pathname


        console.log(url)
        this._setDefaultMenuList(url)
        this.setAccountInfo()

        const auth = this.store.getStore('userAuthInfo')
        this.setState({
            userId: auth.UserId
        })
    }

    render() {
        const {Name, Surname, Role, Points} = this.state.AccountInfo
        const {menuList, userId} = this.state

        const menuItem = menuList.map(item => {
            const {id, label, path, icon, isActive} = item
            const classActive = isActive ? ' active' : null

            if (Role === 'seoshnik' && id === 11) {
                return null
            }
            if (Role === 'linkbuilder' && id === 22) {
                return null
            }
            if ((Role !== 'admin' && Role !== 'manager' && Role !== 'seoshnik') && id === 3) {
                return null
            }
            return (
                <li key={id} className={classActive}>
                    <Link to={path}
                          onClick={() => this.onSelectPage(id)}><i className={`fa ${icon}`}></i>
                        <span>{label}</span></Link>
                </li>
            )
        })


        return (
            <div className="leftpanel">
                <div className="media profile-left">
                    <div className="media-body">
                        <h4 className="media-heading">{`${Name} ${Surname}`}</h4>
                        <small className="text-muted">{Role}
                            {![1, 2, 4, 7, 5, 6].includes(userId) ? (
                                `Points: ${Points}`
                            ) : null}
                        </small>
                    </div>
                </div>
                <ul className="nav nav-pills nav-stacked">
                    {menuItem}
                </ul>
            </div>
        )
    }
}

export default withRouter(PageSidebar)