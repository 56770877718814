import React, {Component} from 'react'
import {Doughnut} from 'react-chartjs-2';
import ApiService from "../../services/ApiService";
import {log10} from "chart.js/helpers";


export default class MainStats extends Component {
    api = new ApiService()

    state = {
        loadingData: true,
        stats: {},
        urlsColor: [
            '#FDEBD0',
            '#7D3C98',
            '#2874A6',
            '#F1C40F',
            '#E67E22',
            '#1B4F72',
            '#D98880',
            '#73C6B6',
            '#F9E79F',
            '#E74C3C'
        ],
    }

    constructor(props) {
        super(props);
    }

    getStats = (url, params) => {
        this.api.getStats(url, params)
            .then(res => this.setState({stats: res, loadingData: true}))
    }

    componentDidMount(){
        this.setState({loadingData: false})
        this.getStats('/links/stats/main/', this.props.params)
    }

    componentDidUpdate(nextProps, nextState) {
        if (((JSON.stringify(this.props.params) !== JSON.stringify(nextProps.params)) ||
            Object.keys(this.state.stats).length === 0) && this.state.loadingData) {
            this.setState({loadingData: false})
            this.getStats('/links/stats/main/', this.props.params)
        }
    }

    render() {
        const {
            stats,
            urlsColor
        } = this.state
        return (Object.keys(stats).length != 0 && this.state.loadingData ?
                <>
                    <div className="chart__container">
                        <Doughnut
                            data={{
                                labels: stats.urls != undefined ? Object.keys(stats.urls) : [],
                                datasets: [
                                    {
                                        label: 'Rainfall',
                                        backgroundColor: urlsColor,
                                        hoverBackgroundColor: ['silver'],
                                        data: stats.urls != undefined ? Object.values(stats.urls) : [],
                                        count: stats.count != undefined ? stats.count.urls : {}
                                    }
                                ]
                            }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                legend: {},
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: function (context) {
                                                var label = context.label;
                                                var label_default = context.label;
                                                var count = context.dataset.count;

                                                if (label) {
                                                    label += ' : ';
                                                    label += context.parsed.toFixed(2);
                                                    label += '%';
                                                    if (Object.values(count).length != 0){
                                                        label += ` (${count[label_default]} links)`
                                                    }
                                                }
                                                return label;
                                            }
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="chart__container">
                        <Doughnut
                            data={{
                                labels: stats.anchor != undefined ? Object.keys(stats.anchor) : [],
                                datasets: [
                                    {
                                        labels: stats.anchor != undefined ? Object.keys(stats.anchor) : [],
                                        backgroundColor: urlsColor,
                                        hoverBackgroundColor: ['silver'],
                                        data: stats.anchor != undefined ? Object.values(stats.anchor) : [],
                                        count: stats.count != undefined ? stats.count.anchor : {}
                                    }
                                ]
                            }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                legend: {},
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: function (context) {
                                                var label = context.label;
                                                var label_default = context.label;
                                                var count = context.dataset.count;

                                                if (label) {
                                                    label += ' : ';
                                                    label += context.parsed.toFixed(2);
                                                    label += '%';
                                                    if (Object.values(count).length != 0){
                                                        label += ` (${count[label_default]} links)`
                                                    }
                                                }
                                                return label;
                                            }
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="chart__container">
                        <Doughnut
                            data={{
                                labels: stats.linkType != undefined ? Object.keys(stats.linkType) : [],
                                datasets: [
                                    {
                                        labels: stats.linkType != undefined ? Object.keys(stats.linkType) : [],
                                        backgroundColor: urlsColor,
                                        hoverBackgroundColor: ['silver'],
                                        data: stats.linkType != undefined ? Object.values(stats.linkType) : [],
                                        count: stats.count != undefined ? stats.count.linkType : {}

                                    }
                                ]
                            }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                legend: {},
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: function (context) {
                                                var label = context.label;
                                                var label_default = context.label;
                                                var count = context.dataset.count;

                                                if (label) {
                                                    label += ' : ';
                                                    label += context.parsed.toFixed(2);
                                                    label += '%';
                                                    if (Object.values(count).length != 0){
                                                        label += ` (${count[label_default]} links)`
                                                    }
                                                }
                                                return label;
                                            }
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="chart__container">
                        <Doughnut
                            data={{
                                labels: stats.crawler != undefined ? Object.keys(stats.crawler) : [],
                                datasets: [
                                    {
                                        labels: stats.crawler != undefined ? Object.keys(stats.crawler) : [],
                                        backgroundColor: ['green', 'red'],
                                        hoverBackgroundColor: ['silver'],
                                        data: stats.crawler != undefined ? Object.values(stats.crawler) : [],
                                        count: stats.count != undefined ? stats.count.crawler : {}
                                    }
                                ]
                            }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                legend: {},
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: function (context) {
                                                var label = context.label;
                                                var label_default = context.label;
                                                var count = context.dataset.count;

                                                if (label) {
                                                    label += ' : ';
                                                    label += context.parsed.toFixed(2);
                                                    label += '%';
                                                    if (Object.values(count).length != 0){
                                                        label += ` (${count[label_default]} links)`
                                                    }
                                                }
                                                return label;
                                            }
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                </>
                : ""
        )
    }
}