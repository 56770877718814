import React, {Component} from 'react'

import ApiService from '../../services/ApiService'
import LinksList from '../../components/LinksList'

export default class PaidLinksPage extends Component {
    api = new ApiService()

    state = {
        pageInfo: {
            title: "Paid links",
            icon: "fa-list-alt"
        },
        params: {
            url:'paid-links',
            // Limit: 50
        },
        linksActionsIsShow: {
            crowler: false,
            users: false,
            anchor: false,
            linkType: false,
            status: false,
            search: false,
            upload: true
        }
    }


    setPageInfo = () => {
        this.props.setPageInfo(this.state.pageInfo)
    }
    componentWillMount() {
        this.setPageInfo()
    }


    render() {
        const {params,linksActionsIsShow} = this.state
        return (
            <div className="col-xs-12">
                <div className="table-responsive">

                    <LinksList pageParams={params} actionsIsShow={linksActionsIsShow} />
                    
                </div>
            </div>
        )
    }
}