// eslint-disable-next-line
import React, {Component} from 'react'


export default class Header extends Component {

    onSignOut = (e) => {
        this.props.onSignOut()
    }


    toggleSidebar = e => {
        e.preventDefault()
        this.props.toggleSidebar()
    }
    
    render() {
        return (
            <header>
                <div className="headerwrapper collapsed">
                    <div className="header-left">
                        <span className="menu-collapse" onClick={(e) => this.toggleSidebar(e)}>
                            <i className="fa fa-bars"></i>
                        </span>
                    </div>
                    <div className="header-right">
                        <div className="pull-right">
                            <button onClick={() => this.onSignOut()} type="button" className="btn btn-light btn-xs">Sign Out</button>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}