import React, {Component} from 'react'
import {Link} from "react-router-dom"
import Loader from './loader'
import LinksActions from './LinksActions'
import ApiService from '../services/ApiService'
import Store from '../services/Store'

import DropdownPicker from './DropdownPicker'

//import Pagination from '../../components/Pagination/'

import Pagination from "react-js-pagination"
import './LinksList.css'
import {log10} from "chart.js/helpers";
import Rodal from "rodal";
import 'rodal/lib/rodal.css';
import ClaculatorPage from "../Pages/ClaculatorPage";
import {NotificationManager} from "react-notifications";


export default class LinksList extends Component {
    api = new ApiService()
    store = new Store()

    state = {
        links: [],
        totalCount: 0,
        filteredCount: 0,
        linklist: '',
        pagePerPage: 50,
        url: "links",
        lockUpdate: false,
        visible: false,
        params: {
            DateFrom: 0,
            DateTo: 0,
            Status: '',
            Limit: 50,
            Offset: 0,
            SortField: 'Date',        //one of [Id, Link, Url, Anchor, AnchorType, LinkType, Date, Crawler, FullUsername, Points, LastContact])
            SortOrder: 'desc',        // (default "asc", ine of [asc, desc])
            SitesIds: [],
            ExcludeLinksIds: [],
            Anchor: ''
        },
        AccountInfo: {},
        selectAction: {
            markAs: '1',
            moveTo: 'problematic'
        },

        checkedLinks: [],
        AllCheckedLink: false,
        exportChecked: 'all',       // one of [all, checked]
        showList: 'all',
        issetChecked: false,
        errorGet: true,
        errorMessage: '',
        loading: true,
        activePage: 1,
        showEditingLink: false,
        openLink: {
            id: 0
        },
        actionParams: {
            users: null,
            sites: null
        },
        linksActionsIsShow: {
            crowler: true,
            users: true,
            anchor: true,
            linkType: true,
            status: true,
            search: true,
            upload: false,
            anchorCloud: false,
            LinkType: true
        }
    }

    componentDidCatch(error) {
        // console.log('Component Did Catch:')
        console.log(error)
    }


    /*
    Function for checking empty array
*/
    emptyArr = (arr) => {
        if (arr.length === 0)
            return true
    }


    show(id, index) {
        this.setState({visible: true, openLink: {id: id, index: index}});
    }

    hide() {
        this.setState({visible: false});
    }

    hideAndUpdate() {
        this.setState({visible: false});
        const {openLink} = this.state
        setTimeout(() => {
            this._getAnchorInfoByLinkId(openLink.id, openLink.index)
        }, 500)
    }

    /*
        Change state for query params by SortField and toggle SortOrder
    */

    _getAnchorInfoByLinkId = (id, index) => {
        this.api.getLinkById(id)
            .then(res => {
                res.ChildLinks = res.AdditionalFields
                let {links} = this.state
                links[index] = res
                this.setState({links: links})
            })
    }

    _setSortParam = (SortField) => {
        const {params} = this.state
        const {SortOrder} = this.state.params
        let newSortOrder = ''
        if (SortOrder === 'asc') {
            newSortOrder = 'desc'
        } else {
            newSortOrder = 'asc'
        }
        const newSort = {SortOrder: newSortOrder, SortField}
        const newParams = {...params, ...newSort}
        this.setState({params: newParams})
    }

    /*
        On table header click
    */
    sortBy = (field) => {
        this._setSortParam(field)
        setTimeout(() => {
            this.getAllLinks()
        }, 100)
    }

    /*
        Get array whith checked links items from state
    */
    _getCheckedLinks = () => {
        const {links} = this.state
        return links.filter(item => item.isChecked === true)
    }

    /*
        Get array with checked LinksIds
    */
    _getLinksIds = (links) => {
        if (this.emptyArr(links)) {
            return ""
        }
        let linksIds = links.map(item => {
            return item.Id
        })
        return linksIds
    }

    /*
        Target function for check item
    */
    setCheckedLinksIds = (id) => {
        const {checkedLinks} = this.state
        const indexId = checkedLinks.indexOf(id)
        let newArrIds = [...checkedLinks]
        if (indexId === -1) {
            newArrIds.push(id)
        } else {
            newArrIds.splice(indexId, 1)
        }
        if (!this.emptyArr(newArrIds)) {
            this.setState({checkedLinks: newArrIds, exportChecked: 'checked', issetChecked: true})
        } else {
            this.setState({checkedLinks: newArrIds, exportChecked: 'all', issetChecked: false})
        }
    }

    /*
        Target Function for toggle check status
    */
    checkLink = (e, id) => {
        const checkStatus = e.target.checked
        this.setState(({links}) => {
            const index = links.findIndex(elem => elem.Id === id)
            const old = links[index]
            const newItem = {...old, isChecked: checkStatus}
            const newArr = [...links.slice(0, index), newItem, ...links.slice(index + 1)]
            return {
                links: newArr
            }
        })
        this.setCheckedLinksIds(id)
    }

    /*
        Reload Links list
    */
    uptList = () => {
        return this.getAllLinks()
    }

    /*
        function links moving
        Return function ReloadList
    */
    onMoveTo = () => {
        const {moveTo} = this.state.selectAction
        const linksChecked = this._getCheckedLinks()
        const LinksIds = this._getLinksIds(linksChecked)
        if (!this.emptyArr(LinksIds)) {
            const data = {Status: moveTo, LinksIds}
            return this.api.updadeStatus(data)
                .then(this.uptList)
        }
    }

    /*
        function links mark
        Return function ReloadList
    */
    onMarkAs = () => {
        const {params, checkedLinks} = this.state
        if (this.emptyArr(checkedLinks))
            return null
        const {ExcludeLinksIds} = params
        const newExlud = [...ExcludeLinksIds, ...checkedLinks]
        const newPar = {...params, ExcludeLinksIds: newExlud}
        this.setState({
            params: newPar,
            checkedLinks: [],
            issetChecked: false,
            AllCheckedLink: false
        })
        setTimeout(() => {
            this.getAllLinks()
        }, 100)
    }

    /*
        function links change select mark
    */
    onChangeMarkAs = (e) => {
        const markAs = e.target.value
        const oldActions = this.state.selectAction
        const newActions = {...oldActions, markAs}
        this.setState({
            selectAction: newActions
        })
    }

    /*
        function links change select move
    */
    onChangeMoveTo = (e) => {
        const {ifExistParent} = this.props
        const moveTo = e.target.value
        const oldActions = this.state.selectAction
        const newActions = {...oldActions, moveTo}
        this.setState({selectAction: newActions})
        if (ifExistParent != undefined && ifExistParent) {
            this.props.onChangeParentProps({selectAction: moveTo})
        }
    }

    initCheckLink = (arr) => {
        arr.forEach(id => {
            this.setState(({links}) => {
                const index = links.findIndex(elem => elem.Id === id)
                if (index !== -1) {
                    const old = links[index]
                    const newItem = {...old, isChecked: true}
                    const newArr = [...links.slice(0, index), newItem, ...links.slice(index + 1)]
                    return {links: newArr}
                }
            })
        })
        this.setAllCheckedLink()
    }

    _updadeList = arr => {
        let links = arr.map(item => {
            return {...item, isChecked: false}
        })
        this.setState({links})
        const {checkedLinks} = this.state
        if (!this.emptyArr(checkedLinks))
            this.initCheckLink(checkedLinks)

        this.setState({loading: false})
    }

    _loadAllLinks = res => {
        const {links, totalCount, filteredCount} = res
        this.setState({links, totalCount, filteredCount, lockUpdate: false})
        this._updadeList(links)
    }

    defaultParams = () => {
        const getParams = this.props.pageParams
        const {params} = this.state
        const newParams = {...params, ...getParams}
        this.setState({params: newParams})
    }

    getRespone = res => {
        if (("links" in res) && res.links)
            return this._loadAllLinks(res)

        const {Message} = res
        this.setState({errorMessage: Message, errorGet: true, loading: false, lockUpdate: false})
    }

    onGetError = res => {
        this.setState({errorMessage: `Fetching error with status: ${res.satus}`, errorGet: true, loading: false})
    }

    getAllLinks = () => {
        const {params, url} = this.state
        this.setState({loading: true})
        this.getAnchorCloud()
        this.api.getLinks(`/${url}/`, params)
            .then((async (res) => {
                for (let i = 0; i < res.links.length; i++) {
                    res.links[i].ChildLinks = []
                    if (res.links[i].LinkType == 'PR-Post') {
                        try {
                            res.links[i] = await this.api.getGroupLinks(res.links[i].GroupId)
                        } catch (e) {
                            console.log(e)
                        }
                    }
                    res.links[i].ParentGroup = res.links[i].Id === res.links[i].GroupId
                }
                return await res
            }))
            .then(res => this.getRespone(res))
            .catch(res => this.onGetError(res))
    }

    selectDate = date => {
        const {DateFrom, DateTo} = date
        const dateFromSec = Math.floor(DateFrom / 1000)
        const dateToSec = Math.floor(DateTo / 1000)
        const {params} = this.state
        const {ifExistParent} = this.props
        const newParams = {...params, DateFrom: dateFromSec, DateTo: dateToSec, Offset: 0}
        this.setState({params: newParams, activePage: 1})
        if (ifExistParent != undefined && ifExistParent) {
            this.props.onChangeParentProps({DateFrom: dateFromSec, DateTo: dateToSec, Offset: 0})
        }
        setTimeout(() => {
            this.getAllLinks()
        }, 100)
    }

    searchInput = e => {
        const Search = e.target.value
        const {params} = this.state
        const newParams = {...params, Search, Offset: 0}
        this.setState({params: newParams, activePage: 1})
        setTimeout(() => {
            this.getAllLinks()
        }, 1500)
    }

    _resetCheckedList = () => {
        const {links} = this.state
        const resetLinksList = links.map(item => {
            return ({...item, isChecked: false})
        })
        return this.setState({
            links: resetLinksList,
            checkedLinks: [],
            exportChecked: 'all',
            showList: 'all',
            issetChecked: false
        })
    }

    onDelete = () => {
        const {checkedLinks, url} = this.state
        if (!this.emptyArr(checkedLinks)) {
            const params = {LinksIds: checkedLinks}
            this.api.deleteLinks(params, `/${url}/`)
                .then(res => {
                    if ("LinksIds" in res) {
                        this.setState({checkedLinks: []})
                        this.getAllLinks()
                    }
                })
        }
    }

    downloadCsv = () => {
        const {params, checkedLinks, url} = this.state
        const LinksIds = checkedLinks.join()
        let downloadParams = {...params, Limit: "10000", ExportCSV: 1, Offset: ''}
        const userRole = this.store.getStore('userRole')
        if (userRole === "admin" || userRole === "manager") {
            downloadParams = {...params, Limit: "", Unlimited: 1, ExportCSV: 1, Offset: ''}
        }
        const LinksIdsArray = LinksIds.split(',');
        if (LinksIds.length && LinksIdsArray.length !== 50) {
            downloadParams = {...params, Limit: '', LinksIds, ExportCSV: 1, Offset: ''}
        }
        this.api.downloadLinks(downloadParams, `/${url}/`)
            .then(this._resetCheckedList())
    }

    onCrowlerRecheck = () => {
        const checked = this._getCheckedLinks()
        if (!this.emptyArr(checked)) {
            const LinksIds = this._getLinksIds(checked)
            const params = {LinksIds}
            this.api.crowlerRecheck(params)
                .then(res => this.getAllLinks())
        }
        return false
    }

    onPageChange = (page) => {
        const {params, params: {Limit}} = this.state
        const newOffset = (+page - 1) * +Limit
        const newParams = {...params, Offset: newOffset}
        this.setState({params: newParams, activePage: page})
        setTimeout(() => {
            this.getAllLinks()
        }, 100)
    }

    updateDate = () => {
        this.getAllLinks()
    }

    fetchCheckedLinks = () => {
        const {params} = this.state
        const {checkedLinks} = this.state
        const stateParams = {...params, LinksIds: checkedLinks}
        this.setState({params: stateParams})

        const newParams = {...params, LinksIds: checkedLinks, Offset: 0}
        this.api.getLinks(newParams)
            .then(res => this.getRespone(res))
            .catch(res => this.onGetError(res))
    }

    fetchAllLinks = () => {
        const {params} = this.state
        const stateParams = {...params, LinksIds: null}
        this.setState({params: stateParams})
        const newParams = {...stateParams, Offset: 0}
        this.api.getLinks(newParams)
            .then(res => this.getRespone(res))
            .catch(res => this.onGetError(res))
    }

    /* 
        action === string: reset, all || checked  
    */
    onCheckedShow = (action) => {
        this.setState({activePage: 1, loading: true, showList: action})
        if (action === "reset") {
            this._resetCheckedList()
            setTimeout(() => {
                this.fetchAllLinks()
            }, 100)
        }
        if (action === "checked") {
            return this.fetchCheckedLinks()
        }
        if (action === "all") {
            return this.fetchAllLinks()
        }
        return null
    }

    setAllCheckedLink = () => {
        const {links} = this.state
        let status = true
        for (let i = 0; i < links.length; i++) {
            let el = links[i]
            if (!el.isChecked) {
                status = false
                break
            }
        }
        this.setState({AllCheckedLink: status})
    }

    setActiveAnchor = (value) => {
        let {params} = this.state
        const {
            ifExistParent,
            onChangeParentProps
        } = this.props
        const stateParams = {...params, Anchor: value}
        this.setState({params: stateParams})


        if (ifExistParent != undefined && ifExistParent) {
            onChangeParentProps({Anchor: value})
        }

        setTimeout(() => {
            this.getAllLinks()
        }, 1)
    }

    checkAllLink = () => {
        const {AllCheckedLink, links, checkedLinks} = this.state
        let newLinksList = []
        let checkAll
        let newArrIds = [...checkedLinks]
        const linksIds = this._getLinksIds(links)
        if (AllCheckedLink) {
            // убрать все
            linksIds.forEach(id => {
                const indexId = newArrIds.indexOf(id)
                if (indexId !== -1) {
                    newArrIds.splice(indexId, 1)
                }
            })
            checkAll = false
        } else {
            // добавить все
            linksIds.forEach(id => {
                const indexId = newArrIds.indexOf(id)
                if (indexId === -1) {
                    newArrIds.push(id)
                }
            })
            checkAll = true
        }
        links.forEach(item => {
            const newItem = {...item, isChecked: checkAll}
            newLinksList.push(newItem)
        })
        this.setState({
            links: newLinksList,
            AllCheckedLink: checkAll,
            checkedLinks: newArrIds,
            issetChecked: checkAll
        })
    }

    showEditingLink = () => {
        if (window.location.pathname === '/drafts' || window.location.pathname === '/drafts/') {
            this.setState({showEditingLink: true})
        }
    }

    getUsersList = () => {
        this.api.getUsers()
            .then(usersFetch => {
                let users = []
                usersFetch.forEach(user => {
                    const userItem = {
                        Name: user.Name,
                        UserId: user.Id
                    }
                    users.push(userItem)
                })
                const {actionParams} = this.state
                const newParams = {
                    ...actionParams,
                    users
                }
                this.setState({actionParams: newParams})
            })
    }

    getSitesList = () => {
        this.api.getSites()
            .then(sites => {
                const {actionParams} = this.state
                const newParams = {
                    ...actionParams,
                    sites
                }
                this.setState({actionParams: newParams})
            })
    }

    getAnchorCloud = () => {
        const {params} = this.state
        this.api.getAnchorCloud(params)
            .then(data => this.setState({anchorCloud: data}))
    }

    onSelectLinkType = e => {
        const {
            ifExistParent,
            onChangeParentProps
        } = this.props
        const LinkType = e.target.value
        const {params} = this.state
        const newPar = {...params, LinkType}

        this.setState({params: newPar})
        if (ifExistParent != undefined && ifExistParent) {
            onChangeParentProps({LinkType: LinkType})
        }
        setTimeout(() => {
            this.getAllLinks()
        }, 100)
    }

    onSelectAnchorType = e => {
        const {
            ifExistParent,
            onChangeParentProps
        } = this.props
        const AnchorType = e.target.value
        const {params} = this.state
        const newPar = {...params, AnchorType}

        this.setState({params: newPar})
        if (ifExistParent != undefined && ifExistParent) {
            onChangeParentProps({AnchorType: AnchorType})
        }
        setTimeout(() => {
            this.getAllLinks()
        }, 100)
    }

    onCheckUser = e => {
        const {
            ifExistParent,
            onCheckUserParent
        } = this.props
        const UserId = e.target.value
        const {params} = this.state
        const newPar = {...params, UserId}

        this.setState({params: newPar})
        if (ifExistParent != undefined && ifExistParent) {
            onCheckUserParent(UserId)
        }
        setTimeout(() => {
            this.getAllLinks()
        }, 100)
    }

    onChangeSite = e => {
        const SiteId = e.target.value
        const status = e.target.checked
        const {
            ifExistParent,
            onChangeParentProps
        } = this.props
        const {params} = this.state
        const {SitesIds} = params
        const newSites = [...SitesIds]

        const indexId = SitesIds.indexOf(SiteId)
        if (status) {
            newSites.push(SiteId)
        } else {
            newSites.splice(indexId, 1)
        }

        const newPar = {...params, SitesIds: newSites}
        this.setState({params: newPar})
        if (ifExistParent != undefined && ifExistParent) {
            onChangeParentProps({SitesIds: newSites})
        }

        setTimeout(() => {
            this.getAllLinks()
        }, 100)
    }

    isShowActionLinks = () => {
        let {linksActionsIsShow} = this.state
        let {actionsIsShow} = this.props
        if (actionsIsShow !== undefined) {
            return actionsIsShow;
        } else {
            return linksActionsIsShow;
        }
    }

    requestCheckIndexingPage = (url, id) => {
        let {links, params} = this.state;
        links[id].IndexingLoading = true;
        this.setState({links: links})

        let data = {'Url': url}
        this.api.requestCheckIndexingPage(data).then(data => {
            links[id].Indexing = data.isIndexing ? 'indexing' : 'no-indexing';
            links[id].IndexingLoading = false;
            this.setState({links: links})
            this.api.createIndexingPage({
                id: links[id].Id,
                type: params.url === 'paid-links' ? 'paid' : 'free',
                indexing: data.isIndexing ? 'indexing' : 'no-indexing',
            })

        })
    }

    changePerPage(event) {
        const {params} = this.state
        const newParams = {...params, ...{Limit: parseInt(event.target.value)}}
        this.setState({params: newParams, pagePerPage: parseInt(event.target.value)})
        setTimeout(() => {
            this.getAllLinks()
        }, 100)
    }

    onChangeUrl = (value, lockUpdate) => {
        const {params} = this.state
        let newParams = params
        newParams.url = value
        if (lockUpdate != undefined) {
            newParams.lockUpdate = lockUpdate
        }
        this.setState({...newParams})

        setTimeout(() => {
            this.showEditingLink()
            this.getAllLinks()
            this.getUsersList()
            this.getSitesList()
        }, 100)

    }

    componentWillMount() {
        this.defaultParams()
    }

    componentDidMount() {
        let {allLink, pageParams} = this.props
        const {params} = this.state

        if (allLink != undefined && allLink) {
            let newParams = params
            newParams.allLink = 1
            this.setState({...newParams})
        }
        this.onChangeUrl(this.props.pageParams.url !== undefined ? this.props.pageParams.url : 'links')

        const userRole = this.store.getStore('userRole')
        const AccountInfo = this.store.getStore('AccountInfo')

        this.setState({userRole, AccountInfo})
    }


    componentDidUpdate(nextProps, nextState) {
        if (this.props.pageParams.url !== undefined && this.props.pageParams.url !== nextState.url && !this.state.lockUpdate) {
            this.onChangeUrl(this.props.pageParams.url, true)
        }
    }


    render() {
        const {
            userRole,
            links,
            activePage,
            totalCount,
            loading,
            errorGet,
            errorMessage,
            exportChecked,
            pagePerPage,
            showList,
            issetChecked,
            AllCheckedLink,
            anchorCloud,
            params,
            url,
            AccountInfo
        } = this.state
        const errorText = errorGet ? <p className="text-center">{errorMessage}</p> : null

        let tableRow = links ? links.map((row, index) => {
            const {
                Id,
                isChecked,
                Link,
                Status,
                Url,
                Anchor,
                AnchorType,
                LinkType,
                DateDeleted,
                DateFormatted,
                CrawlerLinkCode,
                CrawlerUrlState,
                CrawlerUrlRel,
                UserName,
                UserEmail,
                Points,
                ContactsHistory,
                Seller,
                Price,
                Domain,
                Indexing,
                IndexingLoading,
                Time,
                ChildLinks,
                Edited
            } = row
            const editLink = <EditLink id={Id}/>
            const statusClassName = (Status === 'published') ? 'text-success' : Status === 'draft' ? 'text-secondary' : Status === 'deleted' ? 'text-danger' : Status === 'failed' ? 'text-warning' : Status === 'problematic' ? 'text-info' : null
            if (url !== 'paid-links') {
                return (
                    <React.Fragment key={Id}>
                        <tr>
                            <td style={{textAlign: "center"}}>
                                <input type="checkbox" onChange={(e) => {
                                    this.checkLink(e, Id)
                                }} checked={isChecked}/>
                                <br/>
                                {UserEmail === AccountInfo.Email || userRole === 'admin' || userRole === 'manager' ?
                                    <i style={{'cursor': 'pointer'}} onClick={() => {
                                        this.show(Id, index)
                                    }} className="fa fa-pencil"></i>
                                    : null
                                }
                            </td>
                            <td style={{textAlign: "center"}}>
                                {Id}
                                <br/>
                                {Edited == 1 ? 'Edited' : null}
                            </td>
                            <td style={{textAlign: "center"}}>
                                {Domain ? (
                                    <a target="_blank"
                                       rel="noopener noreferrer"
                                       href={`${Link}`}>{Domain}</a>

                                ) : '-'}

                            </td>
                            <td className={statusClassName} style={{textAlign: "center"}}>{Status}</td>
                            <td>{Url}</td>
                            <td style={{textAlign: "center"}}>{Anchor}</td>
                            <td style={{textAlign: "center"}}>{AnchorType}</td>
                            <td>{LinkType}</td>
                            <td>{DateFormatted}</td>
                            <td>{DateDeleted}</td>
                            <td style={{textAlign: "center"}}>{CrawlerLinkCode}</td>
                            <td style={{textAlign: "center"}}
                                className={CrawlerUrlState === 'Live' ? 'text-success' : 'text-danger'}>{CrawlerUrlState === 'Live' ? 'Live' : 'Dead'}</td>
                            <td style={{textAlign: "center"}}>{CrawlerUrlRel}</td>
                            <td style={{textAlign: "center"}}>{UserName}</td>
                            <td style={{textAlign: "center"}}>
                                {Points}
                                {/*<br/>*/}
                                {/*{editLink}*/}
                            </td>
                            {/*<td>*/}
                            {/*    /!*<DropdownPicker updateList={() => this.updateDate()} history={ContactsHistory} linkId={Id} />*!/*/}
                            {/*</td>*/}
                            {/*<td style={{textAlign: "center", minWidth: '150px'}}>*/}
                            {/*    {*/}
                            {/*        Indexing !== undefined && Indexing !== null ?*/}
                            {/*            Indexing == 'indexing' ?*/}
                            {/*                <p style={{color: "green"}}>Indexing <br/>{Time} <br/> <a*/}
                            {/*                    href={`https://www.google.com/search?q=site:${Link}`}*/}
                            {/*                    target="_blank">Check</a></p> :*/}
                            {/*                <p style={{color: "red"}}>No-Indexing <br/>{Time} <br/> <a*/}
                            {/*                    href={`https://www.google.com/search?q=site:${Link}`}*/}
                            {/*                    target="_blank">Check</a></p>*/}
                            {/*            : '-'*/}
                            {/*    }*/}
                            {/*</td>*/}
                            {/*<td style={{textAlign: "center"}}>*/}
                            {/*    {*/}
                            {/*        !IndexingLoading ?*/}

                            {/*            <button onClick={() => this.requestCheckIndexingPage(`${Link}`, index)}*/}
                            {/*                    className="btn btn-sm btn-info">Check Indexed</button>*/}

                            {/*            : 'Wait please ...'*/}
                            {/*    }*/}
                            {/*</td>*/}
                        </tr>
                        {
                            ChildLinks != undefined || ChildLinks.length != 0 ?
                                ChildLinks.map((Crow) => {
                                    return <tr key={Crow.Id}>
                                        <td style={{textAlign: "center"}}></td>
                                        <td style={{textAlign: "center"}}></td>
                                        <td style={{textAlign: "center"}}></td>
                                        <td style={{textAlign: "center"}}></td>
                                        <td>{Crow.Url}</td>
                                        <td style={{textAlign: "center"}}>{Crow.Anchor}</td>
                                        <td style={{textAlign: "center"}}>{Crow.AnchorType}</td>
                                        <td>{Crow.LinkType}</td>
                                        <td></td>
                                        <td></td>
                                        <td style={{textAlign: "center"}}>{Crow.CrawlerLinkCode}</td>
                                        <td style={{textAlign: "center"}}
                                            className={Crow.CrawlerUrlState === 'Live' ? 'text-success' : 'text-danger'}>{Crow.CrawlerUrlState === 'Live' ? 'Live' : 'Dead'}</td>
                                        <td style={{textAlign: "center"}}>{Crow.CrawlerUrlRel}</td>
                                        <td style={{textAlign: "center"}}>{Crow.UserName}</td>
                                        <td style={{textAlign: "center"}}>

                                        </td>
                                    </tr>
                                })
                                : ''
                        }
                    </React.Fragment>
                )
            } else {
                return (
                    <tr key={Id}>
                        <td style={{textAlign: "center"}}>
                            <input type="checkbox" onChange={(e) => {
                                this.checkLink(e, Id)
                            }} checked={isChecked}/>
                        </td>
                        <td style={{textAlign: "center"}}>{Id}</td>
                        <td style={{textAlign: "center"}}>
                            {Domain ? (
                                <a target="_blank"
                                   rel="noopener noreferrer"
                                   href={`${Link}`}>{Domain}</a>

                            ) : '-'}

                        </td>
                        <td className={statusClassName} style={{textAlign: "center"}}>{Status}</td>
                        <td style={{textAlign: "center"}}>{Url}</td>
                        <td style={{textAlign: "center"}}>{Anchor}</td>
                        <td style={{textAlign: "center"}}>{DateFormatted}</td>
                        <td style={{textAlign: "center"}}>{Seller}</td>
                        <td style={{textAlign: "center"}}>{Price}</td>
                    </tr>
                )
            }

        }) : null


        const loadBlock = loading ? <Loader/> : null
        const content = loading ? null : tableRow
        const paginationNav = totalCount > 50 && !loading ? (
            <>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={pagePerPage}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={(page) => this.onPageChange(page)}/>
                <select style={{'margin': '20px 10px', 'border-color': 'silver', 'border-radius': '3px'}}
                        value={params.Limit} onChange={(e) => {
                    this.changePerPage(e)
                }}>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                </select>
            </>

        ) : null

        const emptyList = totalCount === 0 && !loading ? <p className="text-center">List emprty</p> : null
        const {actionParams} = this.state

        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <h5 className="lg-title mb5">Total list count: {totalCount}</h5>
                    </div>
                    {url !== 'paid-links' && !this.props.ifExistParent ?
                        <div className="col-md-4 pull-right">
                            <input type="search" className="form-control" onChange={(e) => this.searchInput(e)}
                                   placeholder="Search"/>
                        </div> : ""
                    }
                </div>
                <LinksActions
                    isShow={this.isShowActionLinks()}
                    handlerCheckedShow={this.onCheckedShow}
                    visibleList={showList}
                    listChecked={issetChecked}
                    exportChecked={exportChecked}
                    downloadCsv={this.downloadCsv}
                    submitCvs={this.submitCvs}
                    selectDate={this.selectDate}
                    onChangeMarkAs={this.onChangeMarkAs}
                    onChangeMoveTo={this.onChangeMoveTo}
                    onMarkAs={this.onMarkAs}
                    onMoveTo={this.onMoveTo}
                    onCrowlerRecheck={this.onCrowlerRecheck}
                    handlerDelete={this.onDelete}
                    actionParamsList={actionParams}
                    handlerCheckUser={this.onCheckUser}
                    handlerSelectAnchorType={this.onSelectAnchorType}
                    handlerSelectLinkType={this.onSelectLinkType}
                    handlerChangeSite={this.onChangeSite}
                    isChecked={issetChecked}
                    anchorCloud={anchorCloud}
                    setActiveAnchor={this.setActiveAnchor}
                    activeAnchor={params.Anchor}
                />
                {/*<div className="divTable">*/}
                {/*    <div className="divTableBody">*/}
                {/*        <div className="divTableRow divTableRowMain">*/}
                {/*            <div className="divTableCell"><input type="checkbox" onChange={(e) => {*/}
                {/*                this.checkAllLink(e)*/}
                {/*            }} checked={AllCheckedLink}/></div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('Id')}>#</div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('Link')}>Link</div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('Url')}>URL</div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('Status')}*/}
                {/*                 title="status!!!!!!!!!!">Category*/}
                {/*            </div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('Anchor')}>Anchor</div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('AnchorType')}>Anchor Type</div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('LinkType')}>Link Type</div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('DateFormatted')}>Date Added</div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('DateDeleted')}>Date Deleted</div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('CrawlerLinkCode')}>Crw code</div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('CrawlerUrlState')}>Crw res</div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('CrawlerUrlRel')}>Crw rel</div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('FullUsername')}>Name</div>*/}
                {/*            <div className="divTableCell" onClick={() => this.sortBy('Points')}>Points</div>*/}
                {/*            <div className="divTableCell">Indexed</div>*/}
                {/*            <div className="divTableCell">Check Indexed</div>*/}
                {/*        </div>*/}
                {/*        {content}*/}
                {/*    </div>*/}
                {/*</div>*/}

                <table className="table mb30">
                    <thead>
                    <tr>
                        {url !== 'paid-links' ?
                            <>
                                <th><input type="checkbox" onChange={(e) => {
                                    this.checkAllLink(e)
                                }} checked={AllCheckedLink}/></th>
                                <th onClick={() => this.sortBy('Id')}>#</th>
                                <th onClick={() => this.sortBy('Link')}>Link</th>
                                <th onClick={() => this.sortBy('Status')} title="status!!!!!!!!!!">Category</th>
                                <th onClick={() => this.sortBy('Url')}>URL</th>
                                <th onClick={() => this.sortBy('Anchor')}>Anchor</th>
                                <th onClick={() => this.sortBy('AnchorType')}>Anchor Type</th>
                                <th onClick={() => this.sortBy('LinkType')}>Link Type</th>
                                <th onClick={() => this.sortBy('DateFormatted')}>Date Added</th>
                                <th onClick={() => this.sortBy('DateDeleted')}>Date Deleted</th>
                                <th onClick={() => this.sortBy('CrawlerLinkCode')}>Crw code</th>
                                <th onClick={() => this.sortBy('CrawlerUrlState')}>Crw res</th>
                                <th onClick={() => this.sortBy('CrawlerUrlRel')}>Crw rel</th>
                                <th onClick={() => this.sortBy('FullUsername')}>Name</th>
                                <th onClick={() => this.sortBy('Points')}>Points</th>
                                {/*<th>Indexed</th>*/}
                                {/*<th>Check Indexed</th>*/}
                            </>
                            :
                            <>
                                <th><input type="checkbox" onChange={(e) => {
                                    this.checkAllLink(e)
                                }} checked={AllCheckedLink}/></th>
                                <th onClick={() => this.sortBy('Id')}>#</th>
                                <th onClick={() => this.sortBy('Link')}>Link</th>

                                <th onClick={() => this.sortBy('Status')} title="status!!!!!!!!!!">Status</th>
                                <th onClick={() => this.sortBy('Url')}>URL</th>
                                <th onClick={() => this.sortBy('Anchor')}>Anchor</th>
                                <th onClick={() => this.sortBy('DateFormatted')}>Date Added</th>
                                <th onClick={() => this.sortBy('Seller')}>Seller Name</th>
                                <th onClick={() => this.sortBy('Price')}>Price</th>
                            </>
                        }

                    </tr>
                    </thead>
                    <tbody>
                    {content}
                    </tbody>
                </table>

                <div style={{'display': 'flex'}}>
                    {paginationNav}
                </div>

                {emptyList}
                {loadBlock}
                {errorText}

                {
                    this.state.visible ?
                        <Rodal customStyles={{'overflowY': 'scroll'}} measure={'%'} width={80} height={95}
                               visible={this.state.visible} onClose={this.hide.bind(this)}>
                            <ClaculatorPage
                                onClose={this.hideAndUpdate.bind(this)}
                                fromModal={true} activeLink={this.state.openLink.id}
                                setPageInfo={{}} setPageInfoEnable={true}>
                            </ClaculatorPage>
                        </Rodal>
                        : null
                }

            </>
        )
    }
}

const EditLink = ({id}) => <Link to={`/calculator/${id}`}>Edit</Link>