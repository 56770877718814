import React, {Component} from 'react'
import ApiService from '../../services/ApiService'

import PeriodPicker from '../../components/PeriodPicker'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default class KpiPage extends Component {
    api = new ApiService()

    state = {
        pageInfo: {
            title: "KPIs",
            icon: "fa-users"
        },
        params: {
            //Search: '',
            SortField: 'id', // (default "Id"; one of [Id, Email, Name, Surname, FullUsername, Role, LinksCount, Points])
            SortOrder: 'asc', // (default "asc", one of [asc, desc])
            DateFrom: 0,
            DateTo: 0
        },

        list: [],

        checkedUsers: [],
        isCheckedUsers: false,
        allListChecked: false
    }

    setPageInfo = () => this.props.setPageInfo(this.state.pageInfo)

    emptyArr = (arr) => {
        if (!Array.isArray(arr))
            return null
            
        if (arr.length === 0) {
            return true 
        } else {
            return false
        }            
    }
    
    setList = list => {
        let newList = list.map( item => {
            return {...item, isChecked: false}
        })
        this.setState({list: newList})
    }

    getUsers = () => {
        const {params} = this.state
        this.api.getUsers(params)
            .then(res => {
                if (res.length) {
                    this.setList(res)
                }
            })
    }

    setCheckedLinksIds = (id) => {
        const {checkedUsers} = this.state
        const indexId = checkedUsers.indexOf(id)
        let newArrIds = [...checkedUsers]
        if (indexId === -1) {
            newArrIds.push(id)
        } else {
            newArrIds.splice(indexId, 1)
        }
        if (!this.emptyArr(newArrIds)) {
            this.setState({checkedUsers: newArrIds, isCheckedUsers: true})
        } else {
            this.setState({checkedUsers: newArrIds, isCheckedUsers: false})
        }
        setTimeout(() => {
            this.checkAllListChecked()
        }, 100)
    }

    checkAllListChecked = () => {
        const {list} = this.state
        let status = true
        for (let i = 0; i < list.length; i++) {
            let el = list[i]
            if (!el.isChecked) {
                status = false
                break
            }
        }
        this.setState({
            allListChecked: status
        })
    }

    checkUser = (e, id) => {
        const checkStatus = e.target.checked
        this.setState(({list}) => {
            const index = list.findIndex(elem => elem.UserId === id)
            const old = list[index]
            const newItem = {...old, isChecked: checkStatus}
            const newArr = [...list.slice(0, index), newItem, ...list.slice(index + 1)]
            return {
                list: newArr
            }
        })
        this.setCheckedLinksIds(id)
    }

    loadingUser = (id, status) => {
        this.setState(({list}) => {
            const index = list.findIndex(elem => elem.UserId === id)
            const old = list[index]
            const newItem = {...old, loading: status}
            const newArr = [...list.slice(0, index), newItem, ...list.slice(index + 1)]
            return {
                list: newArr
            }
        })
    }

    checkAllUsers = () => {
        const {allListChecked, list} = this.state        
        let checkAll
        let newArrIds   = []
        let newUserList = []
        if (allListChecked) {
            checkAll = false
        } else {
            list.forEach(item => newArrIds.push(item.UserId))
            checkAll = true
        }
        list.forEach(item => {
            const newItem = {...item, isChecked: checkAll}
            newUserList.push(newItem)
        })

        this.setState({
            list: newUserList,
            allListChecked: checkAll,
            checkedUsers: newArrIds,
            isCheckedUsers: checkAll
        })
    }

    handlerSort = field => {
        const {SortField, SortOrder} = this.state.params
        const oldParams = {...this.state.params}
        let params = {}
        if (field === SortField) {
            if (SortOrder === 'asc') {
                params = {...oldParams, SortOrder: 'desc'}
            } else {
                params = {...oldParams, SortOrder: 'asc'}
            }
        } else {
            params = {...oldParams,  SortOrder: 'asc', SortField: field}
        }
        this.setState({params}, this.getUsers())
    }

    updUser = user => {
        this.setState(({list}) => {
            const index = list.findIndex(elem => elem.UserId === user.Id)
            const old = list[index]
            const newItem = {...old, ...user}
            const newArr = [...list.slice(0, index), newItem, ...list.slice(index + 1)]
            return {
                list: newArr
            }
        }, this.loadingUser(user.Id, false))
    }

    handlerCheckPeriod = data => {
        const {fieldId, from, to} = data 

        const params = {
            DateFrom: from/1000,
            DateTo: to/1000
        }

        this.loadingUser(fieldId, true)
        this.api.getUserById(fieldId, params)
            .then(res => {
                if ("Id" in res) {
                    this.updUser(res)
                }
            })
    }

    showChecked = () => {
        const {checkedUsers, params} = this.state
        const param = {UsersIds: checkedUsers}

        const newParams = {...params, ...param}
        this.api.getUsers(param)
            .then(res => {
                if (res.length) {
                    this.setList(res)
                    this.setState({checkedUsers: [], isCheckedUsers: false, params: newParams})
                }
            })
    }

    dateSelect = (date, input) => {
        const {params} = this.state
        const timestamp = date ? date.getTime() : 0
        let newParams

        if (input === 'from') {
            newParams = {
                ...params,
                DateFrom: timestamp
            }
        }
        if (input === 'to') {
            newParams = {
                ...params,
                DateTo: timestamp
            }
        }
        if (input === 'reset') {
            newParams = {
                ...params,
                DateFrom: 0,
                DateTo: 0
            }
        }
        this.setState({params: newParams})
        setTimeout(() => {
            this.getUsers()
        }, 100)
    }

    componentDidMount() {
        this.setPageInfo()
        this.getUsers()
    }

    render() {
        const {list, allListChecked, isCheckedUsers} = this.state

        let tableRow = list.map(row => {
            const {Id, FullUsername, Points, BestLink, isChecked, loading, LinksCount} = row


            if ([1, 2, 4, 7, 5, 6].includes(Id)) {
                return null
            }

            let link = '-'
            if (BestLink) {
                link = <LinkTemplate  linkInfo={BestLink} />
            }

            return (
                <tr key={Id} className="text-center">
                    <td><input type="checkbox" onChange={(e) => this.checkUser(e, Id)} checked={isChecked}/></td>
                    <td>{Id}</td>
                    <td>{loading ? "load..." : FullUsername}</td>
                    <td>{loading ? "load..." : LinksCount ? LinksCount : '-'}</td>
                    <td>{loading ? "load..." : Points ? Points : '-'}</td>
                    <td>{loading ? "load..." : link}</td>
                    <td>
                        <PeriodPicker updateByPeriod={this.handlerCheckPeriod} fieldId={Id} />
                    </td>
                </tr>
            )
        })

        
        const checkedShow = isCheckedUsers ? <button type="button" onClick={() => this.showChecked()} className="btn btn-sm btn-info mr10">Show Checked</button> : null               

        const {DateTo, DateFrom} = this.state.params
        return (
            <>
                <div className="row">
                    <div className="col-xs-12 col-lg-6 mb30">
                        <div className="col-md-4">
                            <DatePicker dateFormat="d MMMM, yyyy" selected={DateFrom} maxDate={DateTo ? DateTo : 0} onChange={(date) => this.dateSelect(date, 'from')} customInput={<CustomPicker/>} />
                        </div>
                        <div className="col-md-4">
                            <DatePicker dateFormat="d MMMM, yyyy" selected={DateTo} minDate={DateFrom ? DateFrom : 0} onChange={(date) => this.dateSelect(date, 'to')} customInput={<CustomPicker/>} />
                        </div>
                        <div className="col-md-4">
                        
                            {DateFrom || DateTo ? (
                                <button 
                                    onClick={() => this.dateSelect(null, 'reset')}
                                    type="button" className="btn btn-sm btn-info">Reset</button>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-xs-12">
                        {checkedShow}
                    </div>
                </div>
                <table className="table mb30">
                    <thead>
                        <tr>
                            <th><input type="checkbox" onChange={this.checkAllUsers} checked={allListChecked} /></th>
                            <th onClick={() => this.handlerSort('Id')}>#</th>
                            <th onClick={() => this.handlerSort('FullUsername')}>Name</th>
                            <th onClick={() => this.handlerSort('LinksCount')}>Number of links</th>
                            <th onClick={() => this.handlerSort('Points')}>Points</th>
                            <th onClick={() => this.handlerSort('BestLink')}>Best Link</th>
                            <th>Period</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRow}
                    </tbody>
                </table>      
            </>     
        )
    }
}

const LinkTemplate = ({linkInfo}) => {
    const {Link, Anchor, Points} = linkInfo
    return <a href={Link}>{Anchor} - {Points} points</a>
}

const CustomPicker = ({value, onClick}) => {
    return (
        <div className="input-group">
            <input type="text" defaultValue={value} className="form-control hasDatepicker" onClick={onClick} />
            <span className="input-group-addon" onClick={onClick} ><i className="glyphicon glyphicon-calendar"></i></span>
        </div>        
    )
}