import React, { Component } from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default class PeriodPicker extends Component {

    state = {
        params: {
            fieldId: null,
            from: 0,
            to: 0
        }
    }


    dateSelect = (name, value) => {
        const time = Date.parse(value)
        const {params} = this.state
        let newParams = {...params}
        if (name === 'from') {
            newParams = {...params, from: time}
        }
        if (name === 'to') {
            newParams = {...params, to: time}
        }
        
        if (name === 'reset') {
            newParams = {
                ...params,
                from: 0,
                to: 0
            }
        }
        this.setState({params: newParams}, this.props.updateByPeriod(newParams))
    }


    initField = () => {
        const {fieldId} = this.props
        const {params} = this.state
        const newParams = {...params, fieldId}
        this.setState({params: newParams})
    }


    componentDidMount() {
        this.initField()
    }

    render() {
        const {from, to} = this.state.params

        return (
            <div>
                <DatePicker selected={from} maxDate={to ? to : null} onChange={(date) => this.dateSelect('from', date)} dateFormat="d MMMM, yyyy" customInput={<CustomPicker />}/>
                <DatePicker selected={to} minDate={from ? from : null} onChange={(date) => this.dateSelect('to', date)} dateFormat="d MMMM, yyyy" customInput={<CustomPicker />}/>
                
                {from || to ? (
                    <button onClick={(date) => this.dateSelect('reset')}>reset</button>
                ) : null}
                
            </div>
        )
    }
}

const CustomPicker = ({value, onClick}) => {
    return (
        <input placeholder="select"  type="text" defaultValue={value} className="btn btn-default input-xs btn-xs mr10" onClick={onClick} />
    )
}