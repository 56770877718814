export default class Store {

    constructor() {
        this._store = localStorage
    }

    setStore = (store, name) => {
        this._store[name] = JSON.stringify(store)
        return JSON.parse(this._store[name])
    }

    getStore = (name) => {
        if (this._store.getItem(name) === null) {
            return null
        }
        return JSON.parse(this._store.getItem(name))
    }

    removeStore = (name) => {
        if (this._store.getItem(name) !== null) {
            this._store.removeItem(name)
            return true
        }
        return null
    }
}