import React, {Component} from 'react'

import ApiService from '../../services/ApiService'
import Store from '../../services/Store'
import LinksList from '../../components/LinksList'


export default class MyLinksPage extends Component {

    api = new ApiService()
    store = new Store()

    state = {
        pageInfo: {
            title: "My links",
            icon: "fa-link"
        },
        params: {
            // Limit: 50,
            Status: 'published'
        }
    }

    setParams = () => {
        const {UserId} = this.store.getStore('userAuthInfo')
        const {params} = this.state
        const newParams = {...params, UserId}
        this.setState({params: newParams})
    }

    setPageInfo = () => {
        this.props.setPageInfo(this.state.pageInfo)
    }

    
    componentWillMount() {
        this.setPageInfo()
        this.setParams()
    }

    render() {
        const {params} = this.state
        return (
            <div className="col-xs-12">
                <div className="table-responsive">
                    <LinksList pageParams={params} />
                </div>
            </div>
        )
    }
}