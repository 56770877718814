import React, {Component} from 'react'

import ApiService from '../../services/ApiService'
import LinksList from '../../components/LinksList'

export default class FailedLinksPage extends Component {

    api = new ApiService()

    state = {
        pageInfo: {
            title: "Failed",
            icon: "fa-chain-broken"
        },
        params: {
            Status: 'failed'
        }
    }
    setPageInfo = () => {
        this.props.setPageInfo(this.state.pageInfo)
    }


    componentWillMount() {
        this.setPageInfo()
    }

    render() {
        const {params} = this.state
        return (
            <div className="col-xs-12">
                <div className="table-responsive">
                    <LinksList pageParams={params} />              
                </div>
            </div>
        )
    }
}