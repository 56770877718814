import React, {Component} from 'react'
import {Link} from "react-router-dom"

export default class PageHeader extends Component {    
    state = {
        pageInfo: {
            title: '',
            icon: ''
        }
    }

    setPageInfo = (pageInfo) => {
        this.setState({
            pageInfo
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.pageInfo !== nextProps.setPageInfo) {
            const {setPageInfo} = nextProps
            this.setPageInfo(setPageInfo)
            document.title = `${nextProps.setPageInfo.title} | LB AdminPanel`
        }
    }
    
    render() {
        const {title, icon} = this.state.pageInfo
        return (
            <div className="pageheader">
                <div className="media">
                    <div className="pageicon pull-left">
                        <i className={`fa ${icon}`}></i>
                    </div>
                    <div className="media-body">
                        <ul className="breadcrumb">
                            <li><Link to="/"><i className="glyphicon glyphicon-home"></i></Link></li>
                            <li>{title}</li>
                        </ul>
                        <h4>{title}</h4>
                    </div>
                </div>
            </div>
        )
    }
}