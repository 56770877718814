import React, {Component} from 'react'
import Loader from '../../components/loader/'
import LoginForm from './LoginForm/'
import ApiService from '../../services/ApiService'
import Store from '../../services/Store'

export default class LoginPage extends Component {

    api = new ApiService()
    store = new Store()
    state = {
        data: {},
        errors: false,
        errorText: '',
        loading: false
    }

    onAuth = (userInfo) => {
        // console.log(userInfo)
        const { UserId, AuthToken} = userInfo
        const userAuthInfo = {
          UserId,
          AuthToken
        }

        this.store.setStore(userAuthInfo, 'userAuthInfo')
        this.props.onLogin(this.store.getStore('AuthAccountInfo'))
    }

    onError = (res) => {
        const {Message} = res
        this.setState({
            loading: false,
            errors: true,
            errorText: Message
        })
    }

    onAuthRequest = (formData) => {
        const { username, password, remember} = formData
        const data = {
            Email: username,
            Password: password,
            Remember: remember
        }

        this.setState({
            data,
            loading: true
        })

        this.api.userAuth(data)
            .then(res => {
                if ("Errors" in res) {
                    this.onError(res)
                    return
                }
                this.onAuth(res)
            })
            .catch(error => console.log(error))
    }
    
    
    componentDidMount() {
        document.body.classList.add('signin')
    }
    componentWillUnmount() {
        document.body.classList.remove('signin')
    }

    render() {
        const {loading} = this.state
        const content = loading ? <Loader /> : <LoginForm onSubmit={this.onAuthRequest} />

        return (
            <section>
                <div className="panel panel-signin">
                    <div className="panel-body">    
                        <br/>
                        <h4 className="text-center mb5">Sign In</h4>
                        <div className="mb30"></div>
                        {content}
                    </div>
                </div>
            </section>
        )
    }
}