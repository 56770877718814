import React, {Component} from "react";
import ApiService from "../../services/ApiService";

import MainStats from "../../components/Chart/MainStats"
import LinkBarStats from "../../components/Chart/LinkBarStats"
import LinkLineStats from "../../components/Chart/LinkLineStats";
import LinksList from "../../components/LinksList";
import LiveDeadStats from "../../components/Chart/LiveDeadStats";
import LinkPaidLineStats from "../../components/Chart/LinkPaidLineStats";

import './stats.css'


export default class StatsPage extends Component {
    api = new ApiService()
    state = {
        activeChart: 'main-stats',
        pageInfo: {
            title: "StatsPage",
            icon: "fa-users"
        },
        params: {
            url: 'links',
            Limit: 50,
            DateFrom: 0,
            DateTo: 0,
            Status: '',
            Offset: 0,
            SortField: 'Date',        //one of [Id, Link, Url, Anchor, AnchorType, LinkType, Date, Crawler, FullUsername, Points, LastContact])
            SortOrder: 'desc',        // (default "asc", ine of [asc, desc])
            SitesIds: [],
            ExcludeLinksIds: []
        },
        linksActionsIsShow: {
            crowler: false,
            users: true,
            anchor: true,
            linkType: true,
            status: false,
            search: true,
            upload: false,
            anchorCloud: true
        }
    }
    setPageInfo = () => this.props.setPageInfo(this.state.pageInfo)

    setActiveChart = (item) => this.setState({activeChart: item})


    onCheckUserParent = value => {
        const UserId = value
        const {params} = this.state
        const newPar = {...params, UserId}
        this.setState({params: newPar})
    }

    onChangeParentProps = value => {
        const {params} = this.state
        const newPar = {...params, ...value}
        this.setState({params: newPar})
    }


    getChartComponents = (params) => {
        switch (this.state.activeChart) {
            case 'main-stats':
                return <MainStats params={params}></MainStats>
                break;
            case 'bar':
                return <LinkBarStats params={params}></LinkBarStats>
                break;
            case 'line':
                return <LinkLineStats params={params}></LinkLineStats>
                break;
            case 'livedead':
                return <LiveDeadStats params={params}></LiveDeadStats>
                break;
            case 'money':
                return <LinkPaidLineStats params={params}></LinkPaidLineStats>
                break;
            default:
                return <h1>Not found ChartComponents</h1>
        }
    }

    componentDidMount() {
        this.setPageInfo()
    }

    render() {
        let {
            activeChart,
            params,
            linksActionsIsShow
        } = this.state
        return (
            <div className="col-xs-12">
                <div className="table-responsive">
                    <div className="chart-menu">
                        <div
                            className={activeChart == 'main-stats' ? 'chart-menu__el chart-menu__el-active' : 'chart-menu__el'}
                            onClick={() => {
                                this.setActiveChart('main-stats');
                                this.onChangeParentProps({url: 'links'})
                            }}>
                            <i className="fa fa-pie-chart" aria-hidden="true"></i>
                        </div>
                        <div
                            className={activeChart == 'bar' ? 'chart-menu__el chart-menu__el-active' : 'chart-menu__el'}
                            onClick={() => {
                                this.setActiveChart('bar');
                                this.onChangeParentProps({url: 'links'})
                            }}>
                            <i className="fa fa-bar-chart" aria-hidden="true"></i>
                        </div>
                        <div
                            className={activeChart == 'line' ? 'chart-menu__el chart-menu__el-active' : 'chart-menu__el'}
                            onClick={() => {
                                this.setActiveChart('line');
                                this.onChangeParentProps({url: 'links'})
                            }}>
                            <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </div>
                        <div
                            className={activeChart == 'livedead' ? 'chart-menu__el chart-menu__el-active' : 'chart-menu__el'}
                            onClick={() => {
                                this.setActiveChart('livedead');
                                this.onChangeParentProps({url: 'links'})
                            }}>
                            <i className="fa fa-area-chart" aria-hidden="true"></i>
                        </div>
                        <div
                            className={activeChart == 'money' ? 'chart-menu__el chart-menu__el-active' : 'chart-menu__el'}
                            onClick={() => {
                                this.setActiveChart('money');
                                this.onChangeParentProps({url: 'paid-links'})
                            }}>
                            <i className="fa  fa-money" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div className={`chart chart-${activeChart}`}>
                        {this.getChartComponents(params)}
                    </div>
                    <br/><br/>
                    {
                        params ?
                            <LinksList
                                pageParams={params}
                                actionsIsShow={linksActionsIsShow}
                                ifExistParent={true}
                                onCheckUserParent={this.onCheckUserParent}
                                onChangeParentProps={this.onChangeParentProps}
                                allLink={true}
                            />
                            : null
                    }


                </div>
            </div>
        )
    }

}