import React, {Component} from 'react'
import ApiService from "../../services/ApiService";
import {Bar} from 'react-chartjs-2';


export default class LinkBarStats extends Component {
    api = new ApiService()

    state = {
        loadingData: true,
        activeDate: 'dateMonths',
        stats: {}
    }

    setActiveDate = (item) => this.setState({activeDate: item})

    getStats = (url, params) => {
        this.api.getStats(url, params)
            .then(res => this.setState({stats: res, loadingData: true}))
    }

    componentDidMount() {
        this.setState({loadingData: false})
        this.getStats('/links/stats/links-by-date/', this.props.params)
    }

    componentDidUpdate(nextProps, nextState) {
        if (((JSON.stringify(this.props.params) !== JSON.stringify(nextProps.params)) ||
            Object.keys(this.state.stats).length === 0) && this.state.loadingData) {
            this.setState({loadingData: false})
            this.getStats('/links/stats/links-by-date/', this.props.params)
        }
    }

    render() {
        const {
            stats,
            activeDate
        } = this.state

        return (Object.keys(stats).length != 0 ?
                <>
                    <div className="chart-second-menu">
                        <div
                            className={activeDate == 'dateDays' ? 'chart-menu__el chart-menu__el-active' : 'chart-menu__el'}
                            onClick={() => this.setActiveDate('dateDays')}>
                            Days
                        </div>
                        <div
                            className={activeDate == 'dateMonths' ? 'chart-menu__el chart-menu__el-active' : 'chart-menu__el'}
                            onClick={() => this.setActiveDate('dateMonths')}>
                            Months
                        </div>
                        <div
                            className={activeDate == 'dateYears' ? 'chart-menu__el chart-menu__el-active' : 'chart-menu__el'}
                            onClick={() => this.setActiveDate('dateYears')}>
                            Years
                        </div>
                    </div>
                    <div className="chart-content">
                        <Bar
                            data={{
                                labels: stats[activeDate] != undefined ? Object.keys(stats[activeDate]) : [],
                                datasets: [
                                    {
                                        label: 'Quantity',
                                        backgroundColor: 'rgba(75,192,192,1)',
                                        data: stats[activeDate] != undefined ? Object.values(stats[activeDate]) : [],
                                    }
                                ]
                            }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                legend: {
                                    display: true,
                                    position: 'right'
                                }
                            }}
                        />
                    </div>
                </>
                : ""
        )
    }
}