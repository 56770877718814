import React, {Component} from 'react'
import ApiService from '../../services/ApiService'
import Store from '../../services/Store'

import LinksList from '../../components/LinksList'

export default class ProblematicLinksPage extends Component {

    api = new ApiService()
    store = new Store()
    state = {
        pageInfo: {
            title: "Problematic Links",
            icon: "fa-exclamation-triangle"
        },
        params: {
            Status: 'problematic'
        }
    }

    setPageInfo = () => {
        this.props.setPageInfo(this.state.pageInfo)
        const userRole = this.store.getStore('userRole')
        if (userRole === 'linkbuilder') {
            const {UserId} = this.store.getStore('userAuthInfo')
            const {params} = this.state
            const newParams = {...params, UserId}
            this.setState({params: newParams})
        }   
    }

    componentWillMount() {
        this.setPageInfo()
    }

    render() {
        const {params} = this.state
        return (
            <div className="col-xs-12">
                <div className="table-responsive">
                    <LinksList pageParams={params} />              
                </div>
            </div>
        )
    }
}