import React, {Component, forwardRef} from 'react'

import ApiService from '../../services/ApiService'
import Store from '../../services/Store'

import Loader from '../../components/loader/'
import UploadFile from '../../components/UploadFile'
import CheckedShow from '../../components/CheckedShow/'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Pagination from "react-js-pagination"
import DropdownPicker from '../../components/DropdownPicker'

export default class AnchorsPage extends Component {
    api = new ApiService()
    store = new Store()

    state = {
        pageInfo: {
            title: "Anchors",
            icon: "fa-anchor"
        },
        dataPickers: {
            DateFrom: 0,
            DateTo: 0,
            minDateTo: 0,
            maxDateFrom: 0
        },
        links: [],
        linklist: '',
        pagePerPage: 50,
        totalCount: 0,
        filteredCount: 0,
        params: {
            DateFrom: 0,
            DateTo: 0,
            Limit: 50,
            Offset: 0,
            SortField: 'Date',   //one of [Id, Link, Url, Anchor, AnchorType, LinkType, Date, Crawler, FullUsername, Points, LastContact])
            SortOrder: 'desc', 
            State: 'all',
            SitesIds: []
        },
        checkedLinks: [],
        AllCheckedLink: false,
        exportChecked: 'all',       // one of [all, checked]
        showList: 'all', 
        issetChecked: false,
        errorGet: true,
        errorMessage: '',
        loading: true,
        activePage: 1,
        actionParams: {
            sites: null
        }
    }

    onPageChange = (page) => {
        const {params, params: {Limit}} = this.state
        const newOffset = (+page - 1)*+Limit
        const newParams = {...params, Offset: newOffset}
        this.setState({params: newParams, activePage: page})
        setTimeout(() => {this.getAnchors()}, 100)
    }

    emptyArr = (arr) => {
        if (arr.length === 0) {
            return true 
        } else {
            return false
        }            
    }

    _getLinksIds = (links) => {
        if (this.emptyArr(links)) {
            return ""
        }
        let linksIds = links.map(item => {
            return item.AnchorId
        })
        return linksIds
    }
    
    /* 
        Change state for query params by SortField and toggle SortOrder
    */
   _setSortParam = (SortField) => {
        const {params} = this.state
        const {SortOrder} = this.state.params
        let newSortOrder = ''
        if (SortOrder === 'asc') {
            newSortOrder = 'desc'
        } else {
            newSortOrder = 'asc'
        }
        const newSort = {SortOrder: newSortOrder, SortField}
        const newParams = {...params, ...newSort}
        this.setState({params: newParams})
    }   

    /*
        On table header click
    */
    sortBy = (field) => {
        this._setSortParam(field)
        setTimeout(() => {
            this.getAnchors()
        }, 100)
    }

    /*
        Target function for check item
    */
    setCheckedLinksIds = (id) => {
        const {checkedLinks} = this.state
        const indexId = checkedLinks.indexOf(id)
        let newArrIds = [...checkedLinks]
        if (indexId === -1) {
            newArrIds.push(id)
        } else {
            newArrIds.splice(indexId, 1)
        }
        if (!this.emptyArr(newArrIds)) {
            this.setState({checkedLinks: newArrIds, exportChecked: 'checked', issetChecked: true})
        } else {
            this.setState({checkedLinks: newArrIds, exportChecked: 'all', issetChecked: false})
        }
    }

    checkLink = (e, id) => {
        const checkStatus = e.target.checked
        this.setState(({links}) => {
            const index = links.findIndex(elem => elem.AnchorId === id)
            const old = links[index]
            const newItem = {...old, isChecked: checkStatus}
            const newArr = [...links.slice(0, index), newItem, ...links.slice(index + 1)]
            return {
                links: newArr
            }
        })
        this.setCheckedLinksIds(id)
    }

    dateSelect = (date, input) => {
        const timestamp = date.getTime()
        const {dataPickers} = this.state
        const toDay = new Date().getTime()
        if (input === 'from') {
            const {DateTo} = dataPickers
            if (DateTo === 0) {
                const newDate = {...dataPickers, DateFrom: timestamp, minDateTo: timestamp, DateTo: toDay}
                return this.setState({dataPickers: newDate})
            }
            const newDate = {...dataPickers, DateFrom: timestamp, minDateTo: timestamp}
            return this.setState({dataPickers: newDate})
        }
        if (input === 'to') {
            const newDate = {...dataPickers, DateTo: timestamp, maxDateFrom: timestamp}
            return this.setState({dataPickers: newDate})
        }
    }

    onResetDate = () => {
        const date = {DateFrom: 0, DateTo: 0}
        this.selectDate(date)
    }

    onShowByDate = () => {
        const {DateFrom, DateTo} = this.state.dataPickers
        if (DateFrom !== 0 || DateTo !== 0) {
            const date = {DateFrom, DateTo}
            this.selectDate(date)
            return
        }
        return false
    }

    selectDate = date => {
        const {DateFrom, DateTo} = date
        const dateFromSec = Math.floor(DateFrom / 1000)
        const dateToSec = Math.floor(DateTo / 1000)
        const {params} = this.state
        const newParams = {...params, DateFrom: dateFromSec, DateTo: dateToSec, Offset: 0}
        this.setState({params: newParams, activePage: 1})
        setTimeout(() => {this.getAnchors()}, 100)
    }

    /*
        Add custom keys to links list Array
    */
    initCheckLink = (arr) => {
        arr.forEach(id => {
            this.setState(({links}) => {
                const index = links.findIndex(elem => elem.AnchorId === id)
                if (index !== -1) {
                    const old = links[index]
                    const newItem = {...old, isChecked: true}
                    const newArr = [...links.slice(0, index), newItem, ...links.slice(index + 1)]
                    return {links: newArr}
                }
            })
        })
        this.setAllCheckedLink()
    }
    
    _updadeList = (arr) => {
        let links = arr.map( item => {
            return {...item, isChecked: false}
        })
        this.setState({links})
        const {checkedLinks} = this.state
        if (!this.emptyArr(checkedLinks))
            this.initCheckLink(checkedLinks)

        this.setState({loading: false})
    }

    _loadAllLinks = (res) => {
        const {anchors, totalCount, filteredCount} = res
        this.setState({
            links: anchors, totalCount, filteredCount
        })

        this._updadeList(anchors)
    }

    getRespone = (res) => {
        if ("anchors" in res)
            return this._loadAllLinks(res)
        const {Message, Errors} = res
        console.error(Errors)
        this.setState({errorMessage: Message, errorGet: true, loading: false})
    }

    getAnchors = () => {
        this.setState({loading: true})
        const {params} = this.state
        this.api.getAnchors(params)
            .then(res => this.getRespone(res))
            .catch(res => console.log(res))
    }

    searchInput = (e) => {
        const Search = e.target.value
        const {params} = this.state
        const newParams = {...params, Search, Offset: 0}
        this.setState({params: newParams, activePage: 1})
        setTimeout(() => {this.getAnchors()}, 1500)
    }

    _resetCheckedList = () => {
        const {links} = this.state
        const resetLinksList = links.map(item => {
            return ({...item, isChecked: false})
        })
        return this.setState({
            links: resetLinksList,
            checkedLinks: [],
            exportChecked: 'all',
            showList: 'all', 
            issetChecked: false
        })
    }
    downloadCsv = () => {
        const {params, checkedLinks} = this.state
        const LinksIds = checkedLinks.join()
        const userRole = this.store.getStore('userRole')

        let downloadParams = {...params, Limit: "10000", ExportCSV: 1}
        if ( userRole === "admin" || userRole === "manager") {
            downloadParams = {...params, Limit: "", Unlimited: 1, ExportCSV: 1}
        }
        
        if (LinksIds.length) {
            downloadParams = {...params, Limit: '', LinksIds, ExportCSV: 1}
        }
        this.api.downloadLinks(downloadParams)
            .then(this._resetCheckedList())
    }

    setPageInfo = () => {
        this.props.setPageInfo(this.state.pageInfo)
    }

    updateDate = () => {
        this.getAnchors()
    }

    // список отмеченых анкоров
    fetchCheckedLinks = () => {
        const {params} = this.state
        const {checkedLinks} = this.state
        const stateParams = {...params, AnchorsIds: checkedLinks}
        this.setState({params: stateParams})
        const newParams = {...params, AnchorsIds: checkedLinks, Offset: 0}
        this.api.getAnchors(newParams)
            .then(res => this.getRespone(res))
            .catch(res => this.onGetError(res))
    }

    // список всех анкоров
    fetchAllLinks = () => {
        const {params} = this.state
        const stateParams = {...params, AnchorsIds: null}
        this.setState({params: stateParams})
        const newParams = {...stateParams, Offset: 0}
        this.api.getAnchors(newParams)
            .then(res => this.getRespone(res))
            .catch(res => this.onGetError(res))
    }

    onCheckedShow = (e, action) => {
        e.preventDefault()
        /* info === string:   reset, all || checked  */
        this.setState({activePage: 1, loading: true, showList: action})
        if (action === "reset") {
            this._resetCheckedList()
            return this.fetchAllLinks()
        }
        if (action === "checked") {
            return this.fetchCheckedLinks()
        }
        if (action === "all") {
            return this.fetchAllLinks()
        }
        return null
    }

    setAllCheckedLink = () => {
        const {links} = this.state 
        let status = true
        for (let i = 0; i < links.length; i++) {
            let el = links[i]
            if (!el.isChecked) {
                status = false
                break
            }
        }
        this.setState({
            AllCheckedLink: status
        })
    }

    checkAllLink = () => {
        const {AllCheckedLink, links, checkedLinks} = this.state 
        let newLinksList = []
        let checkAll
        let newArrIds = [...checkedLinks]
        const linksIds = this._getLinksIds(links)
        if (AllCheckedLink) {
            // убрать все
            linksIds.forEach(id => {
                const indexId = newArrIds.indexOf(id)
                if ( indexId !== -1) {
                    newArrIds.splice(indexId, 1)
                }
            })
            checkAll = false
        } else {
            // добавить все
            linksIds.forEach(id => {
                const indexId = newArrIds.indexOf(id)
                if ( indexId === -1) {
                    newArrIds.push(id)
                }
            })
            checkAll = true
        }
        links.forEach(item => {
            const newItem = {...item, isChecked: checkAll}
            newLinksList.push(newItem)
        })
        this.setState({
            links: newLinksList,
            AllCheckedLink: checkAll,
            checkedLinks: newArrIds,
            issetChecked: checkAll
        })
    }

    // удалить анкоры
    handlerDelete = () => {
        // получаем отмеченные
        const {checkedLinks} = this.state
        if (!this.emptyArr(checkedLinks)) {
            // формируем обьект
            const params = {AnchorsIds: checkedLinks}
            // отправляем запрос
            this.api.deleteAnchors(params)
                .then(res => {
                    if ("AnchorsIds" in res) {
                        this.setState({checkedLinks: []})
                        this.getAnchors()                        
                    }
                })
        }
    }

    initSitesList = sites => {
        const {sitesList} = this.state
        if (sitesList !== sites)
            this.setState({sitesList: sites})
    }

    getSitesList = () => {
        this.api.getSites()
            .then(sites => {
                const {actionParams} = this.state
                const newParams = {
                    ...actionParams,
                    sites
                }
                this.setState({actionParams: newParams})
            })
    }

    onSelectLinkType = e => {
        const LinkType = e.target.value
        const {params} = this.state
        const newPar = {...params, LinkType}

        this.setState({params: newPar})
        setTimeout(() => {
            this.getAnchors()
        }, 100)
    }

    onSelectAnchorType = e => {
        const AnchorType = e.target.value
        const {params} = this.state
        const newPar = {...params, AnchorType}

        this.setState({params: newPar})
        setTimeout(() => {
            this.getAnchors()
        }, 100)
    }

    onChangeSite = e => {
        const SiteId = e.target.value
        const status = e.target.checked

        const {params} = this.state
        const {SitesIds} = params
        const newSites = [...SitesIds]

        const indexId = SitesIds.indexOf(SiteId)
        if (status) {
            newSites.push(SiteId)
        } else {
            newSites.splice(indexId, 1)
        }

        const newPar = {...params, SitesIds: newSites}
        this.setState({params: newPar})
        setTimeout(() => {
            this.getAnchors()
        }, 100)
    }

    onChangeAnchorState = e => {
        const AnchorState = e.target.value
        const {params} = this.state
        const {State} = params
        console.log(AnchorState, State)
        const newPar = {...params, State: AnchorState}
        this.setState({params: newPar})
        setTimeout(() => {
            this.getAnchors()
        }, 100)
    }

    componentDidMount() {
        this.getSitesList()
        this.setPageInfo()
        this.getAnchors()
        const userRole = this.store.getStore('userRole')
        this.setState({userRole})
    }

    componentDidUpdate() {
        const {sites} = this.state.actionParams
        if (sites)
            this.initSitesList(sites)
    }

    render() {
        const {links, totalCount, loading, errorGet, errorMessage, exportChecked, activePage, pagePerPage, showList, issetChecked, AllCheckedLink} = this.state
        const errorText = errorGet ? <p className="text-center">{errorMessage}</p> : null

        function getDomain(url) {
            const urlArray = url.split("/");
            return urlArray[2];
        }

        let tableRow = links.map(row => {
            const {AnchorId, isChecked, LinkId, Status, Url, Link, LinkType, Anchor, AnchorType, ContactsHistory, UserName, Points, DateFormatted} = row

            return (
                <tr key={AnchorId} className={LinkId && (Status === 'published' || Status === 'drafts') ? 'success' : null}>
                    <td>
                        <input type="checkbox" onChange={(e) => {this.checkLink(e, AnchorId)}} checked={isChecked}/>
                    </td>
                    <td>{AnchorId}</td>
                    <td style={{textAlign: "center"}}><a target="_blank" rel="noopener noreferrer" href={Link ? Link : null}>{Link ? getDomain(Link) : '-'}</a></td>
                    <td>{LinkType}</td>
                    <td>{Url}</td>
                    <td>{Anchor}</td>
                    <td>{AnchorType}</td>
                    <td>{UserName}</td>
                    <td>{Points}</td>
                    <td>{DateFormatted}</td>
                    <td><DropdownPicker updateList={() => this.updateDate()} history={ContactsHistory} linkId={AnchorId} /></td>
                </tr>
            )
        })

        const loadBlock = loading ? <Loader/> : null
        const content = loading ? null : tableRow
        const paginationNav = totalCount > 50 && !loading ? <Pagination
                                                    activePage={activePage}
                                                    itemsCountPerPage={pagePerPage}
                                                    totalItemsCount={totalCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={(page) => this.onPageChange(page) }/> : null

        const emptyList = totalCount === 0 && !loading && !errorGet ? <p className="text-center">List emprty</p> : null
        let exportBtn = 'btn btn-sm mr10 btn-dark'
        let exportBtnLabel = 'Export All'
        let deleteBtn
        if (exportChecked === 'checked') {
            exportBtnLabel = 'Export Checked'
            exportBtn += ' btn-danger'
        }

        const {userRole, sitesList} = this.state

        if ((userRole === 'admin' || userRole === 'manager') && issetChecked) {
            deleteBtn = <button type="button" className="btn btn-sm btn-danger mr10" onClick={() => this.handlerDelete()}>Delete</button>
        }

        const {DateFrom, DateTo, minDateTo, maxDateFrom} = this.state.dataPickers

        let sitesListTMPL
        if (sitesList) {
            sitesListTMPL = sitesList.map(site => {
                const {Domain, Id} = site
                return (
                    <div key={Id} className="checkbox d-inline mr10">
                        <label><input
                            onChange={(e) => this.onChangeSite(e)}
                            type="checkbox" name="SitesIds" value={Id} />{Domain}</label>
                    </div>
                )
            })
        }

        return (
            <>
                <div className="row mb30">
                    <div className="col-md-6">
                        <h5 className="lg-title mb5">Total list count: {totalCount}</h5>
                        <p className="mb20">An example of table with actions in every rows.</p>
                    </div>
                    <div className="col-xs-12 btn-group">
                        {deleteBtn}
                        <button className={exportBtn} onClick={() => this.downloadCsv()}>{exportBtnLabel}</button>
                        <UploadFile handleLoadedFile={() => this.getAnchors()}/>
                    </div>
                </div>

                <div className="row mb30">
                    <div className="col-xs-12 col-md-9 col-lg-8">
                        <CheckedShow 
                            resetChecked={this.onCheckedShow}
                            issetChecked={issetChecked} 
                            showList={showList} />
                        <div className="col-md-3">
                            <DatePicker dateFormat="d MMMM, yyyy" selected={DateFrom} maxDate={maxDateFrom} onChange={(date) => this.dateSelect(date, 'from')} customInput={<CustomPicker/>} />
                        </div>
                        <div className="col-md-3">
                            <DatePicker dateFormat="d MMMM, yyyy" selected={DateTo} minDate={minDateTo} onChange={(date) => this.dateSelect(date, 'to')} customInput={<CustomPicker/>} />
                        </div>
                        <div className="col-md-3">
                            <button 
                                onClick={this.onShowByDate}
                                type="button" className="btn btn-sm btn-info mr10">Show</button>
                            <button 
                                onClick={this.onResetDate}
                                type="button" className="btn btn-sm btn-info">Reset</button>
                        </div>
                    </div>
                    <div className="col-md-3 col-lg-4 pull-right">
                        <input type="search" className="form-control" onChange={(e) => this.searchInput(e)} placeholder="Search" />
                    </div>

                    <div className="col-xs-12 mt30 mb30">
                        <div className="row">
                            <div className="col-xs-12 col-sm-4">
                                <div className="col-xs-12 col-sm-8">
                                    sort by LinkType:
                                    <select className="form-control" onChange={(e) => this.onSelectLinkType(e)}>
                                        <option value="">All</option>
                                        <option value="GB">GB</option>
                                        <option value="PIY">PIY</option>
                                        <option value="Profile">Profile</option>
                                        <option value="Forum">Forum</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <div className="col-xs-12 col-sm-8">
                                    sort by AnchorType:
                                    <select className="form-control" onChange={(e) => this.onSelectAnchorType(e)}>
                                        <option value="">All</option>
                                        <option value="brand">brand</option>
                                        <option value="url">url</option>
                                        <option value="generic">generic</option>
                                        <option value="keyword">keyword</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <div className="col-xs-12 col-sm-8">
                                    Sort by Anchor State <br/>
                                    <div key="0" className="checkbox d-inline mr10">
                                        <label><input
                                            onChange={(e) => this.onChangeAnchorState(e)}
                                            type="radio" name="AnchorState" value="" /> All
                                        </label>
                                    </div>
                                    <div key="1" className="checkbox d-inline mr10">
                                        <label><input
                                            onChange={(e) => this.onChangeAnchorState(e)}
                                            type="radio" name="AnchorState" value="used" /> Used
                                        </label>
                                    </div>
                                    <div key="2" className="checkbox d-inline mr10">
                                        <label><input
                                            onChange={(e) => this.onChangeAnchorState(e)}
                                            type="radio" name="AnchorState" value="unused" /> Free
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {userRole !== 'linkbuilder' ? (
                        <div className="col-xs-12 col-lg-offset-1 col-lg-10">
                            {sitesListTMPL}
                        </div>
                        ) : null
                    }

                </div>               

                <table className="table mb30">
                    <thead>
                        <tr>
                            <th><input type="checkbox" onChange={(e) => {this.checkAllLink(e)}} checked={AllCheckedLink} /></th>
                            <th onClick={() => this.sortBy('Id')}>#</th>
                            <th onClick={() => this.sortBy('Link')}>Link</th>
                            <th onClick={() => this.sortBy('LinkType')}>Link Type</th>
                            <th onClick={() => this.sortBy('Url')}>URL</th>
                            <th onClick={() => this.sortBy('Anchor')}>Anchor</th>
                            <th onClick={() => this.sortBy('AnchorType')}>Anchor Type</th>
                            <th onClick={() => this.sortBy('FullUsername')}>Name</th>
                            <th onClick={() => this.sortBy('Points')}>Points</th>
                            <th onClick={() => this.sortBy('DateFormatted')}>Date added</th>
                            <th onClick={() => this.sortBy('LastContact')}>Last Contact</th>
                        </tr>
                    </thead>
                    <tbody>
                        {content}
                    </tbody>
                </table>
            
                {paginationNav}
                {emptyList}
                {loadBlock}
                {errorText}
            </>
        )
    }
}
const CustomPicker = forwardRef (({value, onClick}) => {
    return (
        <div className="input-group">
            <input type="text" defaultValue={value} className="form-control hasDatepicker" onClick={onClick} />
            <span className="input-group-addon" onClick={onClick} ><i className="glyphicon glyphicon-calendar"></i></span>
        </div>        
    )
})