import React, {Component, forwardRef} from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import ApiService from '../services/ApiService'
import Store from "../services/Store";

export default class DropdownPicker extends Component {
    store = new Store()
    api = new ApiService() 
    state = {
        selectDate: null,
        addContanctStatus: false,
        history: [],
        linkId: null
    }

    dateSelect = selectDate => {
        // console.log(selectDate)
        this.setState({
            selectDate,
            addContanctStatus: true
        })
    }

    setDefault = (linkId, history) => {
        const selectDate = history ? Date.parse(history[0]) : null
        this.setState({linkId, history, selectDate})
    }

    componentDidMount() {
        const userRole = this.store.getStore('userRole')
        this.setState({userRole})
        const {linkId, history} = this.props 
        this.setDefault(linkId, history)
    }

    addToHistory = (e, linkId) => {
        e.preventDefault()
        const {selectDate} = this.state 
        if (!selectDate)
            return false
            
        const data = {Date: selectDate.getTime() / 1000}
        this.api.addContactHistory(linkId, data)
            .then(() => this.props.updateList())
            .catch(res => console.log(res))
    }

    render() {
        const {selectDate, linkId, history, addContanctStatus, userRole} = this.state
        return (
            <div className={"btn-group d-flex align-items-center justify-end"} style={userRole === 'seoshnik' ? {pointerEvents: "none"} : null}>
                <HistoryBlock history={history} />
                <DatePicker 
                    selected={selectDate} 
                    onChange={(date) => this.dateSelect(date)} 
                    showTimeSelect
                    dateFormat="d MMMM, yyyy h:mm"  
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="time"
                    popperPlacement="bottom-end"
                    customInput={<CustomPicker />} />
                <button disabled={!addContanctStatus ? true : null} type="button" onClick={(e) => this.addToHistory(e, linkId)} className="btn btn-xs dropdown-toggle"><b>+</b></button>
            </div>
        )
    }
}


const CustomPicker = forwardRef((props, ref) => {
    const {value, onClick} = props
    return <input ref={ref} placeholder="Select new contact"  type="text" defaultValue={value} className="btn btn-default btn-xs" onClick={onClick} />
})


const HistoryBlock = ({history}) => {
    if (history.length === 0)
        return null
    const listContacts = history.map((item, index) => {
        return <li key={index}>{item}</li>
    })
    return (
        <>
            <button type="button" className="btn btn-secondary btn-xs dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="caret"></span>
                <span className="sr-only">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu dropdown-overflow-scroll dropdown-menu-right">
                {listContacts}
            </ul>
        </>
    )
}